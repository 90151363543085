import apiConfig from '../../../config/api/apiConfig';
import httpService from '../../httpService';
import Logger from '../../Logger';
import constants from '../../../config/constants';
import { getCommerceHeaders } from '../../tenantHelper';
import { getQuantityUnitForPayload } from '../../CartHelpers';

const addToListCommerce = async ({
  appCtx,
  tagName,
  product,
  listType = 'MY_FAVORITES'
}) => {
  const { regionCode, priceGroupId, politicalId, store } = appCtx;
  const { ATC_SESSION_EXPIRED_CODE } = constants;

  const listLines = [
    {
      item: {
        productId: product.productId,
        variantId: product.variantId,
        offeringId: product.offeringId,
        sellerId: product.sellerId
      },
      listLineNumber: product.offeringId,
      quantity: {
        quantityNumber: '1',
        quantityUnit: getQuantityUnitForPayload({ store }),
        actualValue: '1',
        minSaleUnit: '1',
        maxSaleUnit: '1'
      },
      priceGroup: priceGroupId
    }
  ];

  const reqBody = {
    data: {
      list: {
        listLines
      }
    },
    metadata: {
      priceGroup: 'default'
    }
  };

  reqBody.metadata.politicalAreaId = politicalId;
  reqBody.metadata.priceGroup = priceGroupId;

  const additionalHeaders = getCommerceHeaders(regionCode, store, true);

  const url = new URL(apiConfig.addToListCommerceUrl(regionCode));
  url.searchParams.set('listType', listType);
  url.searchParams.set('tagName', tagName);

  const response = await httpService().post(
    url,
    reqBody,
    {
      credentials: 'include'
    },
    additionalHeaders,
    'json'
  );
  const defaultResponse = {
    tagName
  };
  if (response.statusCode === 200) {
    return { ...defaultResponse, data: response.data, ok: true };
  }
  Logger.error(`Failed to Add to list`);

  if (response.statusCode === 401) {
    return {
      ...defaultResponse,
      ok: false,
      error_code: ATC_SESSION_EXPIRED_CODE
    };
  }
  return { ...defaultResponse, ok: false, error_code: 'UNKNOWN' };
};

export default addToListCommerce;
