import React, { useState, useEffect } from 'react';
import { ZoneModal } from '@digital-retail/falabella-ui-cross-components';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { isSodimacStandalone } from '@digital-retail/store-manager';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { Modal } from '../../ui/Modal/Modal';
import DeliveryOptionsContent from './DeliveryOptionsContent/DeliveryOptionsContent';
import {
  getPickupInStoreData,
  processStoreId,
  getHomeDeliveryData,
  getScreen
} from './DeliveryOptions.Helper';
import { getVariant } from '../../../utils/variant';
import { withProduct } from '../../../utils/ProductContext';
import SelectVariant from '../../Cart/SelectVariant/SelectVariant';
import { withCart } from '../../../utils/CartContext';
import noop from '../../../utils/noop';

const DeliveryOptionsModal = ({
  type,
  appCtx,
  variant,
  modalVisible,
  setModalVisible,
  product: { variants },
  zoneConfig,
  setZoneConfig,
  cart,
  sizes,
  colorVariants,
  selectedDeliveryOption,
  setSelectedDeliveryOption
}) => {
  const {
    deviceType,
    isRebrandingEnabled,
    regionCode,
    isDefaultComuna,
    reloadOnZoneChange,
    store
  } = appCtx;

  const { isSizeSelected } = cart;

  const selectedZone = _.get(appCtx, 'comuna.data', undefined);

  const { offerings = [], isFreeShippingApplicable = false } = getVariant(
    variants || [],
    variant
  );
  const offeringId =
    offerings.length > 0 && offerings[0].offeringId
      ? offerings[0].offeringId
      : variant;

  const defaultScreen = getScreen(type, sizes, colorVariants, isSizeSelected);

  const [homeDeliveryData, setHomeDeliveryData] = useState([]);
  const [pickupInStoreData, setPickupInStoreData] = useState([]);
  const [selectedStoreDetails, setSelectedStoreDetails] = useState([]);
  const [selectedStoreId, setSelectedStoreId] = useState(null);
  const [screen, setScreen] = useState(defaultScreen);
  const [isError, setIsError] = useState(false);
  const [comunaName, setComunaName] = useState('');
  const [isCambiar, setIsCambiar] = useState(false);
  const [selectedStoreName, setSelectedStoreName] = useState('');
  const [saveError, setSaveError] = useState(false);

  useEffect(() => {
    if (type === 'PickupInStore' && selectedStoreDetails.length > 0) {
      setScreen('screen3');
    }
  }, [selectedStoreDetails]);

  useEffect(() => {
    if (saveError) {
      if (type === 'HomeDelivery' && !isCambiar) {
        setSelectedDeliveryOption({
          selectedType: 'No disponible para',
          date: '',
          month: '',
          price: '',
          locationName: comunaName.toLocaleLowerCase(),
          storeName: '',
          storeId: '',
          isError: true,
          isFreeShippingApplicable: false
        });
      } else if (type === 'PickupInStore') {
        if (
          selectedDeliveryOption &&
          !_.isEmpty(selectedDeliveryOption) &&
          !isCambiar
        ) {
          setSelectedDeliveryOption({
            selectedType: 'No disponible',
            date: '',
            month: '',
            price: '',
            locationName: comunaName.toLocaleLowerCase(),
            storeName: selectedDeliveryOption.storeName,
            politicalId: selectedDeliveryOption.politicalId,
            storeId: selectedDeliveryOption.storeId,
            isError: true,
            isFreeShippingApplicable: false
          });
        }
      }
      setSaveError(false);
    }
  }, [saveError]);

  const saveZones = (comuna) => {
    setZoneConfig({
      ...comuna
    });
    if (type === 'HomeDelivery') {
      if (!isError) {
        setScreen('screen3');
      } else if (isDefaultComuna) {
        setModalVisible(false);
      }
    } else if (type === 'PickupInStore') {
      processStoreId(
        selectedStoreId,
        pickupInStoreData,
        setSelectedStoreId,
        setSelectedStoreDetails,
        setSelectedStoreName,
        regionCode
      );
    }
  };

  const checkAvailability = () => {
    if (type === 'HomeDelivery') {
      if (zoneConfig && !_.isEmpty(zoneConfig)) {
        setScreen('screen2');
      } else {
        setScreen('screen2');
      }
    } else if (type === 'PickupInStore') {
      setScreen('screen2');
    }
  };

  const getPickupInStoreSlotsData = (comuna, setBusy, initial) => {
    getPickupInStoreData({
      comuna,
      setBusy,
      initial,
      isDefaultComuna: _.isEmpty(zoneConfig),
      isCambiar,
      regionCode,
      store,
      zoneConfig,
      offeringId,
      setComunaName,
      setIsError,
      setPickupInStoreData,
      selectedDeliveryOption,
      setSelectedStoreId,
      setSelectedStoreDetails,
      setSelectedStoreName,
      setSaveError
    });
  };

  const getHomeDeliverySlotsData = (comuna, setBusy, initial) => {
    getHomeDeliveryData({
      comuna,
      setBusy,
      initial,
      zoneConfig,
      isDefaultComuna,
      isCambiar,
      regionCode,
      store,
      offeringId,
      isFreeShippingApplicable,
      setComunaName,
      setIsError,
      setHomeDeliveryData,
      setScreen,
      setSaveError
    });
  };

  const onCambiar = () => {
    setIsCambiar(true);
    setScreen('screen2');
  };

  const getTitle = () => {
    return type === 'HomeDelivery' ? 'Envío a domicilio' : 'Retiro en un punto';
  };

  const getModalContent = (actions) => {
    if (screen === 'screen1') {
      return (
        <SelectVariant
          cart={cart}
          handleOnClose={() => setModalVisible(false)}
          config={{
            title: deviceType === 'desktop' ? '¿Cuál buscas?' : getTitle(),
            iconClass:
              type === 'HomeDelivery'
                ? 'homeDelivery-icon'
                : 'pickupInStore-icon'
          }}
          inDeliveryOptions
          checkAvailability={() => checkAvailability()}
        />
      );
    }

    if (screen === 'screen2') {
      return (
        <ZoneModal
          deviceType={deviceType}
          tenant={regionCode}
          entry={{}}
          handleRouteChange={reloadOnZoneChange}
          isBrandingMarketplace={isRebrandingEnabled}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          isOnboardingInProgress={false}
          isStore={!!appCtx.store}
          enableSisThemedBorder
          isSodimacStandAlone={isSodimacStandalone(appCtx.store)}
          isDefaultComuna={
            type === 'PickupInStore' ? _.isEmpty(zoneConfig) : isDefaultComuna
          }
          selectedZone={type === 'PickupInStore' ? zoneConfig : selectedZone}
          isPopOverVisible={false}
          deliveryOptions={{
            title:
              type === 'HomeDelivery'
                ? 'Envío a domicilio'
                : 'Retiro en un punto',
            type,
            setZoneConfig: ({ comuna }) => saveZones(comuna, actions.setBusy),
            setSelectedStoreId: (storeId) => setSelectedStoreId(storeId),
            getPickupInStoreData: (comuna, initial = false) =>
              getPickupInStoreSlotsData(comuna, actions.setBusy, initial),
            getHomeDeliveryData: (comuna, initial = false) =>
              getHomeDeliverySlotsData(comuna, actions.setBusy, initial),
            actions: {
              close: () => setModalVisible(false),
              setBusy: (toggle) => actions.setBusy(toggle)
            },
            pickupInStoreData,
            selectedPickupInStoreId: selectedStoreId,
            error: isError,
            comunaName
          }}
        />
      );
    }

    if (screen === 'screen3') {
      return (
        <DeliveryOptionsContent
          title={
            type === 'HomeDelivery' ? 'Envío a domicilio' : 'Retiro en un punto'
          }
          type={type}
          data={
            type === 'HomeDelivery' ? homeDeliveryData : selectedStoreDetails
          }
          setBusy={actions.setBusy}
          zoneConfig={zoneConfig}
          isDefaultComuna={isDefaultComuna}
          setHomeDeliveryData={setHomeDeliveryData}
          regionCode={regionCode}
          variant={variant}
          offeringId={offeringId}
          onCambiar={onCambiar}
          setIsCambiar={setIsCambiar}
          setSelectedDeliveryOption={setSelectedDeliveryOption}
          isFreeShippingApplicable={isFreeShippingApplicable}
          deviceType={deviceType}
          selectedStoreName={selectedStoreName}
        />
      );
    }

    return <React.Fragment />;
  };

  return (
    <div className="container">
      <Modal
        content={getModalContent}
        fullScreen={deviceType !== 'desktop'}
        options={{
          width: deviceType === 'desktop' ? '500' : '100vw',
          height: deviceType === 'desktop' ? '531' : '100vh',
          overflow: 'hidden',
          disableOutsideClick: false
        }}
        stopScroll
        withPadding={false}
        toggleState={{
          visible: modalVisible,
          setVisible: setModalVisible
        }}
        deviceType={deviceType}
        isRebrandingEnabled={isRebrandingEnabled}
      />
    </div>
  );
};

DeliveryOptionsModal.defaultProps = {
  setModalVisible: noop,
  product: {},
  zoneConfig: {},
  setZoneConfig: noop,
  sizes: [],
  colorVariants: [],
  selectedDeliveryOption: {},
  setSelectedDeliveryOption: noop
};

DeliveryOptionsModal.propTypes = {
  type: PropTypes.string.isRequired,
  appCtx: PropTypes.object.isRequired,
  variant: PropTypes.string.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func,
  product: PropTypes.object,
  zoneConfig: PropTypes.object,
  setZoneConfig: PropTypes.func,
  cart: PropTypes.object.isRequired,
  sizes: PropTypes.array,
  colorVariants: PropTypes.array,
  selectedDeliveryOption: PropTypes.object,
  setSelectedDeliveryOption: PropTypes.func
};

export { DeliveryOptionsModal };
export default withProduct(
  withCart(withApplicationContext(DeliveryOptionsModal))
);
