const bazaarVoice = `
#BVRRContainer .bv-cv2-cleanslate .bv-section-summary-inline .bv-secondary-rating-summary,
#BVRRContainer .bv-cv2-cleanslate .bv-action-bar h2.bv-action-bar-header,
#BVRRContainer .bv-cv2-cleanslate .bv-content-item .bv-feedback-container div.bv-content-feedback-vote.bv-content-feedback-vote-active button.bv-content-report-btn.bv-focusable,
#BVRRContainer .bv-cv2-cleanslate .bv-content-data-summary .bv-content-meta .bv-content-datetime-dot,
#BVRRContainer .bv-cv2-cleanslate .bv-content-details-container,
#BVRRContainer .bv-cv2-cleanslate .bv-relevancy-link-target-visible,
#BVRRContainer .bv-cv2-cleanslate .bv-control-bar .bv-expand-filter-button,
#BVRRContainer .bv-cv2-cleanslate .bv-section-summary-inline .bv-secondary-rating-summary .bv-table .bv-secondary-rating-summary-stars.bv-table-row,
#BVRRContainer .bv-cv2-cleanslate .bv-section-summary .bv-section-summary-inline .bv-inline-histogram-ratings .bv-histogram-filter-helper,
.bv-cv2-cleanslate div.bv-searchbar {
  display: none !important;
}
.bv-cv2-cleanslate .bv-core-container-379 .bv-submission .bv-fieldsets .bv-rating-helper.bv-rating-helper-1{
  padding: .6em .6em .3em 1em!important;
}
.bv-cleanslate.bv-cv2-cleanslate .bv-content-search,
#BVRRSearchContainer .bv-cv2-cleanslate .bv-content-search .bv-masthead .bv-masthead-product{
  border-bottom: 0 !important;
}
#BVRRSearchContainer .bv-cv2-cleanslate .bv-rating-stars-container {
    zoom: 1.4 !important;
}
/* M */ #BVRRContainer .bv-cv2-cleanslate .bv-header .bv-action-bar {
  border-bottom: 0 !important;
  margin-bottom: 25px !important;
  margin-left: 5rem !important;
  margin-right: 5rem !important;
  width: 30% !important;
    float: right !important;
}
#BVRRContainer .bv-content-summary-body-text {
  display: flex !important;
  order: 2;
  width: 70% !important;
  flex-wrap: wrap;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-form-actions .bv-submission-button-submit {    color: #333333 !important;    
background-color: #ffffff !important;    
border: 1px solid #333333 !important;    
padding: 12px 25px !important;    
border-radius: 5px !important;    
letter-spacing: 1px !important;    
margin-bottom: 20px !important;    
margin-top: 10px !important;    
font-size: 12px !important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets li.bv-radio-container-li-active label.bv-radio-wrapper-label {
background-color: #333333 !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-rating-stars-container .bv-rating-stars, 
#BVRRSearchContainer .bv-cv2-cleanslate .bv-content-search .bv-masthead .bv-masthead-product .bv-stars-container .bv-rating-stars-on,
#BVRRSearchContainer .bv-cv2-cleanslate .bv-content-search .bv-masthead .bv-masthead-product .bv-stars-container .bv-rating-stars-off {
    letter-spacing: 4px !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-list-container .bv-content-placeholder,
#BVRRContainer .bv-cv2-cleanslate ol,
#BVRRContainer .bv-cv2-cleanslate .bv-control-bar.bv-control-bar-filter-offset,
#BVRRContainer .bv-cv2-cleanslate .bv-section-summary,
#BVRRSearchContainer .bv-cv2-cleanslate .bv-content-search .bv-masthead .bv-masthead-product, .bv-cv2-cleanslate .bv-generic-submission .bv-masthead .bv-masthead-product {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-flex-container-column {
  margin: 15px 10px 20px 0 !important;
  width: 80% !important;
}
/* #BVRRContainer .bv-cv2-cleanslate .bv-flex-container-column .bv-flex-container div:first-child {
    text-decoration: underline !important; 
}*/
.bv-cv2-cleanslate .bv-inline-histogram-ratings .bv-inline-histogram-ratings-star .bv-glyph {
  color: #FFC000 !important;
  text-decoration: none !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-inline-histogram-ratings .bv-inline-histogram-ratings-star .bv-glyph {
  font-size: 1.7rem !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-secondary-ratings-container {
    background-color: #ffffff !important;
    border-radius: 0 !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-flex-container-column .bv-flex-container div:last-child {
    text-align: left !important;
    font-size: 12px !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-secondary-rating-summary-value,
#BVRRContainer .bv-cv2-cleanslate .bv-content-secondary-ratings-value {
  background-image: none !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-section-summary {
  margin-bottom: 0 !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-section-summary-inline .bv-secondary-rating-summary .bv-secondary-rating-summary-list {
    margin: 10px 10px 20px 0!important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-section-summary-inline .bv-secondary-rating-summary .bv-secondary-rating-summary-bars .bv-secondary-rating-summary-bar {
      -webkit-box-shadow: inset 1px 0 0 rgba(255,255,255,.5),inset -1px 0 0 rgba(0,0,0,0)!important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-section-summary-inline .bv-secondary-rating-summary .bv-secondary-rating-summary-container .bv-secondary-rating-summary-bars {
    height: 10px!important;
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0)!important;
    box-shadow: inset 0 0 2px rgba(0,0,0,0)!important;
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
    background-color: #ffffff !important;
    border: 1px solid #F1F0F1 !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-secondary-ratings-container {
  -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0)!important;
    box-shadow: inset 0 0 2px rgba(0,0,0,0)!important;
    border: 1px solid #F1F0F1 !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-section-summary-inline .bv-secondary-rating-summary .bv-secondary-rating-summary-value {
    height: 10px!important;
    -webkit-border-radius: 0!important;
    border-radius: 0!important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-section-summary-inline .bv-secondary-rating-summary .bv-secondary-rating-summary-bars .bv-secondary-rating-summary-bar:first-child {
  border-radius: 0 !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-section-summary-inline .bv-secondary-rating-summary .bv-secondary-rating-summary-bars .bv-secondary-rating-summary-bar {
  height: 10px !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-section-summary-table.bv-table-full-width .bv-section-summary-inline.bv-flex-container-responsive {
  border-bottom: 0 !important;
  padding-top: 20px !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-control-bar.bv-control-bar-filter-offset {
  padding-top: 10px !important;
  padding-bottom: 20px !important;
    margin-bottom: 0 !important;
    border-bottom: 0 !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-control-bar-count {
  padding-left: 0 !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-pagination-pages-current {
    font-size: 13px !important;
    font-weight: 400 !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-control-bar-sort {
  width: 250px !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-dropdown-target {
  padding: 10px 25px 10px 22px!important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-dropdown-target button,
#BVRRContainer .bv-cv2-cleanslate .bv-dropdown-target .bv-dropdown-label {
  float: left !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-dropdown-target button {
  clear: both !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-dropdown-target .bv-dropdown-label {
  font-size: 12px !important;
  color: #9B9B9B !important;
  font-weight: 400 !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-dropdown-arrow {
  margin-top: 10px!important;
  color: #4a4a4a !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-dropdown-target .bv-dropdown-title {
  font-size: 14px !important;
  color: #333333 !important;
  font-weight: bold !important;
}
#BVRRContainer .bv-content-list-container {
  background-color: #ffffff !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-header {
  padding-top: 20px !important;
  position: relative !important;
    width: 100% !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-data-summary {
  display: flex !important;
    flex-direction: row;
    flex-shrink: 0;
    position: relative !important;
    flex-wrap: wrap;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-header-meta {
  display: flex !important;
    order: 2;
    margin-top: 0 !important;
    width: 100% !important;
    flex-wrap: wrap;
    margin-bottom: 3px !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-title-container {
  display: flex !important;
  order: 1;
  width: 100% !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-meta-wrapper,
#BVRRContainer .bv-cv2-cleanslate .bv-content-rating {
    display: flex!important;
    order: 2;
    flex-wrap: wrap;
    width: 100% !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-meta-wrapper {
    order: 1;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-data-summary .bv-content-meta .bv-content-reference-data {
  padding-left: 0 !important;
    border-left: 0 !important;
    margin-left: 0 !important;
    width: 100% !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-data-summary .bv-content-meta .bv-author span.bv-author::before {
  content: "Por ";
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-data-summary .bv-content-meta .bv-content-datetime {
  float: right !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-data-summary .bv-content-meta {
  width: 100% !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-data-summary .bv-content-meta .bv-content-datetime-stamp {
  text-transform: capitalize !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-item-avatar-offset-off .bv-content-rating .bv-rating-stars-container {
  margin-top: 5px !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-core {
    position: relative !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-media-container {
    max-width: 180px !important;
    height: 75px !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-media-container .bv-media-item {
    width: 75px!important;
    margin-left: 2% !important;
    height: 75px !important;
    float: right !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-media-container .bv-media-item .bv-media-item-wrapper {
  padding-top: 100% !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-media-container .bv-media-item .bv-media-item-border {
  border: 1px solid rgba(0,0,0,.1)!important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-data {
    position: relative !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-tag-dimensions,
#BVRRContainer .bv-cv2-cleanslate .bv-content-product-questions {
  margin-bottom: 0 !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-media-container {
    max-width: 160px !important;
    height: 75px !important;
    position: absolute !important;
    bottom: 0 !important;
    right: 0 !important;
    width: 100% !important;
    margin-bottom: -35px !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-media-container .bv-media-item {
    width: 75px!important;
    margin-left: 2% !important;
    height: 75px !important;
    float: right !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-details-offset-on {
  width: 100% !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-actions-container {
  margin-left: 0 !important;
  padding-left: 0 !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-data {
    margin-bottom: 0.7rem!important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-item .bv-content-feedback-vote-request p {
  font-size: 12px !important; 
  color: #333333 !important;
  font-weight: 300 !important;
}
#BVRRContainer .bv-content-summary-body {
  display: flex !important;
  flex-wrap: wrap !important;
}
#BVRRContainer .bv-content-summary-body-text {
  display: flex !important;
  order: 2;
  width: 70% !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-data {
  display: flex !important;
  order: 1;
  width: 100% !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-data-label-container .bv-content-data-icon {
  color: #8FCA00 !important;
  font-size: 18px !important;
    vertical-align: text-bottom !important;
    padding-right: 3px !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-data .bv-content-data-value,
#BVRRContainer .bv-cv2-cleanslate .bv-content-data-label-container .bv-content-data-label {
  font-size: 11px !important;
  letter-spacing: 0.2px !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-data-label-container {
  margin-right: 1px !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-item .bv-content-feedback-vote .bv-content-feedback-btn-container button.bv-content-btn.bv-content-btn-feedback-no,
#BVRRContainer .bv-cv2-cleanslate .bv-content-item .bv-content-feedback-vote .bv-content-feedback-btn-container button.bv-content-btn.bv-content-btn-feedback-yes {
  border: 1px solid #aaaaaa !important;
    background-color: #ffffff !important;
    border-radius: 3px !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-item .bv-content-feedback-vote .bv-content-feedback-btn-container button.bv-content-btn.bv-content-btn-feedback-yes {
  border: 1px solid #8DCB00 !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-item .bv-content-feedback-vote .bv-content-feedback-btn-container button.bv-content-btn.bv-content-btn-feedback-no:hover,
#BVRRContainer .bv-cv2-cleanslate .bv-content-item .bv-content-feedback-vote .bv-content-feedback-btn-container button.bv-content-btn.bv-content-btn-feedback-yes:hover {
  background: #8DCB00 !important;
  color: #ffffff !important;
  box-shadow: inset 0 0 5px rgba(0,0,0,0)!important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-item .bv-content-feedback-vote .bv-content-feedback-btn-container button.bv-content-btn.bv-content-btn-feedback-no:hover {
  background: #aaaaaa !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-item .bv-content-feedback-vote .bv-content-feedback-btn-container * {
  font-size: 11px !important;
    font-weight: 300 !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-item .bv-content-btn:first-child {
    margin-right: 8px!important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-item .bv-content-btn {
  padding: 2px 7px!important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-focusable:focus, .bv-cv2-cleanslate .bv-pseudo-focused, .bv-cv2-cleanslate .bv-tc-footer .bv-accept-tc-button:focus {
  outline-width: 0!important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-pagination .bv-content-pagination-container {
  border-top: 1px solid #F0F0F0 !important;
  margin-top: 20px !important;
    margin-left: 5rem !important;
    margin-right: 5rem !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-btn {
  color: #333333 !important;
  background-color: #ffffff !important;
  background-image: none !important;
  border: 1px solid #979797 !important;
  border-radius: 5px !important;
  width: auto !important;
  margin-bottom: 20px !important;
    margin-top: 20px !important;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-btn:hover {
  box-shadow: inset 0 0 5px rgba(0,0,0,0)!important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-pagination .bv-content-btn-pages span.bv-content-btn-pages-load-more-text{
  font-size: 12px !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  font-family: Lato, sans-serif !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-pagination .bv-content-btn-pages span.bv-content-btn-pages-load-more-text::before {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='4' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h1.007c.16 0 .296.055.406.164L3.76 2.588l.165.194.165-.194L6.435.164a.408.408 0 01.188-.127A.695.695 0 016.84 0h.977L4.555 3.66H3.277L0 0z' fill='%23333' fill-rule='evenodd'/%3E%3C/svg%3E");
  width: 8px;
  height: 4px;
}
#BVRRContainer .bv-cv2-cleanslate .bv-control-bar .bv-content-btn-pages-load-more, .bv-cv2-cleanslate .bv-content-pagination .bv-content-btn-pages-load-more {
    margin: 10px auto 20px!important;
    width: auto!important;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-data-summary .bv-content-title {
  color: #4a4a4a !important;
  font-size: 14px !important;
}
#BVRRSearchContainer .bv-cv2-cleanslate .bv-summary-bar .bv-rating-ratio-number .bv-rating {
  font-size: 16px !important;
}
#BVRRSearchContainer .bv-cv2-cleanslate .bv-summary-bar .bv-rating-ratio-number .bv-rating::after {
  content: " / 5" !important;
}
#BVRRSearchContainer .bv-cv2-cleanslate .bv-content-search .bv-masthead .bv-masthead-product .bv-rating-label {
  font-size: 14px !important;
  font-weight: bold !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-header {
  position: relative !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-write-review-container {
  width: 233px !important;
}
/* .bv-cv2-cleanslate .bv-write-review-container::before {
  content: "\\00BFHas comprado este producto?  " !important;
  float: right !important;
  font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.1px;
    font-family: "Lato",sans-serif!important;
    vertical-align: middle;
    padding-right: 25px !important;
    padding-bottom: 9px !important;
    text-align: center !important;
    width: 100%;
} */
#BVRRSearchContainer .bv-cv2-cleanslate .bv-write-review-container {
  width: 470px !important;
}
#BVRRSearchContainer .bv-cv2-cleanslate .bv-action-bar button {
  float: right !important;
}
#BVRRContainer .bv-cv2-cleanslate button.bv-write-review {
  background-color: #ffffff !important;
  font-size: 12px !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
  color: #333333 !important;
  border-radius: 5px !important;
  border: 1px solid #333333 !important;
  width: auto !important;
  padding: 11px 3rem !important;
  float: right !important;
  margin-top: 10px !important;
}
#BVRRContainer .bv-cv2-cleanslate button.bv-write-review::before {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='4' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h1.007c.16 0 .296.055.406.164L3.76 2.588l.165.194.165-.194L6.435.164a.408.408 0 01.188-.127A.695.695 0 016.84 0h.977L4.555 3.66H3.277L0 0z' fill='%23333' fill-rule='evenodd'/%3E%3C/svg%3E");
  width: 8px;
  height: 4px;
  float: left;
}
#BVRRSearchContainer .bv-cv2-cleanslate .bv-stars-container:before {
  content: "Calificaci\\00F3n general" !important;
  font-weight: bold !important;
  font-size: 16px !important;
  color: #333333 !important;
  padding-bottom: 10px !important;
}
.bv-cv2-cleanslate .bv-write-review-container {
  margin-left: 0 !important;
    text-align: right !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-section-summary {
  margin-bottom: 0 !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-section-summary-table {
  margin-bottom: 0 !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-section-summary .bv-content-title {
  font-size: 14px;
}
#BVRRContainer .bv-cv2-cleanslate .bv-rating-stars-container.bv-rating-none .bv-rating-stars-off {
  font-size: 34px !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-rating-stars-container.bv-rating-none .bv-off-screen {
  position: relative !important;
  font-family: Lato, sans-serif !important;
  width: auto!important;
  height: auto!important;
  color: #888888 !important;
  padding-top: 5px !important;
  display: inline-block !important;
}  
#BVRRContainer .bv-cv2-cleanslate .bv-content-placeholder .bv-rating-stars-container.bv-rating-none {
    margin-bottom: 20px!important;
    margin-top: 10px !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-placeholder button.bv-write-review-label.bv-text-link {
    float: right !important;
    margin-top: 20px !important;
    background-color: #ffffff !important;
    border: 1px solid #333333 !important;
    text-align: center !important;
    padding: 12px 25px !important;
    border-radius: 5px !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
  letter-spacing: 1px !important;
  font-weight: bold !important;
  text-decoration: none !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-placeholder button.bv-write-review-label.bv-text-link { 
  text-decoration: none !important;
}
#BVRRSearchContainer .bv-cv2-cleanslate .bv-content-search, 
#BVRRSearchContainer .bv-cv2-cleanslate .bv-generic-submission {
  border: 0 !important;
}
#BVRRSearchContainer .bv-cleanslate.bv-cv2-cleanslate .bv-masthead h3.title-bye {
  padding-top: 30px !important;
}
#BVRRContainer .bv-cv2-cleanslate .bv-content-list-container h3.title-bye {
  padding-top: 30px !important;
}


/* REVIEWS RESUMEN & ANCHOR STYLES */
.reviews-column [data-bv-show=rating_summary] .bv_main_container {
    margin-bottom: 10px!important;
    margin-top: 10px!important;
}
.reviews-column [data-bv-show=rating_summary] .bv_main_container .bv_main_container_row_flex {
    padding-right: 0.5em!important;
}
.reviews-column [data-bv-show=rating_summary] .bv_main_container .bv_stars_component_container,
.reviews-column [data-bv-show=rating_summary] .bv_main_container #ratings-summary.bv_main_container_row_flex {
    padding-right: 0.5em!important;
}
.reviews-column [data-bv-show=rating_summary] .bv_main_container .bv_stars_component_container .bv_stars_button_container svg:not(:last-child) {
    padding-right: 4px!important;
}
[data-bv-show=rating_summary] .bv_main_container .bv_button_buttonMinimalist,
.reviews-column [data-bv-show=rating_summary] .bv_main_container .bv_button_buttonMinimalist {
  color: #6B8E23 !important;
  font-weight: bold !important;
  font-size: 14px !important;
  letter-spacing: 0.2px !important;
}
.bv-cv2-cleanslate h3.title-bye {
    font-weight: 400 !important;
    margin-bottom: 2rem !important;
    padding-bottom: 2rem !important;
    border-bottom: 2px solid #aad500 !important;
    margin-right: 5rem !important;
    margin-left: 5rem !important;
    padding-top: 60px !important;
    font-size: 1.4em !important;
    width: auto !important;
    font-family: Lato, sans-serif !important;
}
.bv_button_component_container .bv_button_buttonFull {
  margin-top: 10px !important;
  padding: 0 !important;
  text-align: left !important;
  background-image: none !important;
  box-shadow: inset 0 1px 0 hsla(0,0%,100%,0)!important;
    color: #6B8E23!important;
    text-transform: none !important;
    letter-spacing: 0.3px !important;
    background-color: transparent !important;
    border: 0 !important;
}
.bv_button_component_container .bv_button_buttonFull:hover {
  text-align: left !important;
  background-image: none !important;
  box-shadow: inset 0 1px 0 hsla(0,0%,100%,0)!important;
    color: #6B8E23!important;
    text-decoration: underline !important;
    text-transform: none !important;
    letter-spacing: 0.3px !important;
    background-color: transparent !important;
    border: 0 !important;
}
div.bv_modal_component_container[data-bv-modal]>div.bv_modal_outer_content {
    padding: 1em!important;
    border: 0 !important;
    box-shadow: 0 0 3px rgba(0,0,0,.2)!important;
}
div.bv_modal_component_container[data-bv-modal]>div.bv_modal_outer_content:before {
    border-right: 1px solid #eee!important;
    border-bottom: 1px solid #eee!important;
    transform: rotate(-135deg)!important;
    background-color: #FFFFFF!important;
    box-shadow: 0 0 3px rgba(0,0,0,0)!important;
}
.bv_histogram_row_prefix {
    padding-right: 5px !important;
}
.bv_histogram_row_full_bar .bv_histogram_row_bar_empty, 
.bv_histogram_row_full_bar .bv_histogram_row_bar_filled {
    border-radius: 0!important;
    height: 10px!important;
    display: block!important;
}
.bv_histogram_row_full_bar .bv_histogram_row_bar_empty {
  background-image: none !important;
  border: 1px solid #F1F0F1 !important;
  background-color: #ffffff !important;
  height: 12px !important;
}
.bv_histogram_row_full_bar .bv_histogram_row_bar_filled {
  background-image: none !important;
}
.bv-cv2-cleanslate .bv-inline-histogram {
    width: 50%!important;
    vertical-align: top!important;
}
.bv-cv2-cleanslate .bv-section-summary-inline .bv-secondary-rating-summary {
    width: 50%!important;
    margin: 0!important;
}


/* MODAL WRITE A COMMENT STYLES */

#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-mbox .bv-mbox-breadcrumb {
  font-size: 18px !important;
  font-weight: 400 !important;
  color: #333333 !important;
  padding: 20px 38px 0 12px!important;
  border-radius: 12px !important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-required-fields-text {
  padding: 0 12px!important; 
  font-size: 10px !important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-rating-link {
  background-color: #dddddd !important;
  border-color: #eeeeee !important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-submission-star-rating .bv-rating-link span {
  color: #ffffff !important;
    text-shadow: .02em .02em 2px transparent!important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldset-r2 .bv-submission-star-rating-on .bv-rating-link {
  color: #FFC000 !important;
  border-color:  transparent !important;
  text-shadow: 0 -.05em transparent!important;
  background-color: #FFC000 !important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-core-container-0 .bv-submission .bv-submission-star-rating-control.bv-heat-map-off .bv-submission-star-rating-hover .bv-rating-link {
  color: #FFC000 !important;
  border-color:  transparent !important;
  text-shadow: 0 -.05em transparent!important;
  background-color: #FFC000 !important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets li label.bv-radio-wrapper-label, #bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-rating-link, #bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-select {
  box-shadow: 0 0 0 #fff,inset 0 0 0 #fff!important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-submission-star-rating-control.bv-heat-map-off .bv-submission-star-rating-hover .bv-rating-link, #bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-submission-star-rating-control.bv-heat-map-off .bv-submission-star-rating-on .bv-rating-link {
  background-color: #FFC000 !important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-mbox .bv-mbox-close, #bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-mbox .bv-content-item-close {
    top: 7px!important;
    right: 10px!important;
    font-size: 25px!important;
    color: #bbbbbb!important;
}
#bv-mboxzone-lightbox#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset.bv-valid .bv-helper-icon-positive, #bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset.bv-valid .bv-fieldset-select-wrapper .bv-helper-icon-positive,
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset.bv-valid .bv-helper-icon-positive, #bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset.bv-valid .bv-fieldset-select-wrapper .bv-helper-icon-positive,
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset.bv-error .bv-helper-icon-negative, #bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset.bv-error .bv-fieldset-select-wrapper .bv-helper-icon-negative {
  display: none !important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset-rating .bv-fieldset-rating-wrapper .bv-rating-helper {
    color: #4a4a4a!important;
    font-family: Lato, sans-serif !important;
    font-size: 14px !important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset-label .bv-fieldset-label-text {
  color: #333333 !important;
  font-size: 15px !important;
  font-weight: normal !important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset {
  border-top: 0 !important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets input.bv-text, #bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets textarea {
  border-color: #bbbbbb !important;
  border-radius: 0 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets input:focus, 
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets textarea:focus, 
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets select:focus, 
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset:hover input:focus, 
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset:hover textarea:focus, 
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset:hover select:focus, 
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-active input, 
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-active textarea, 
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-active select, 
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-active:hover input, 
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-active:hover textarea, 
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-active:hover select, 
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset:hover .bv-review-field-content-wrapper, 
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-active .bv-review-field-content-wrapper, 
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-active:hover .bv-review-field-content-wrapper {
  border-color: #888888 !important;
  border-radius: 0 !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-review-field-content-wrapper,
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset.bv-error .bv-helper, 
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-mincount .bv-helper {
  border: 0 !important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset .bv-helper {
  padding-right: 12px !important;
  right: 0 !important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets textarea,
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets input.bv-text {
  padding: 0 1em .5em 0!important;
  font-size: 13px!important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset .bv-review-field-content-wrapper .bv-review-media div.bv-review-media-actions, 
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset.bv-fieldset-active .bv-review-field-content-wrapper .bv-review-media div.bv-review-media-actions {
  padding: 0 15px 0 0 !important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-form-actions .bv-fieldset-casltext {
    margin-top: 5px!important;
    font-size: 10px!important;
    line-height: 130% !important;
    margin-bottom: 20px !important;
}


#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-fieldset-secondary-rating .bv-rating-helper {
  font-size: 13px !important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets .bv-form-actions .bv-submission-button-submit {
    color: #333333 !important;
    background-color: #ffffff !important;
    border: 1px solid #333333 !important;
    padding: 12px 25px !important;
    border-radius: 5px !important;
    letter-spacing: 1px !important;
    margin-bottom: 20px !important;
    margin-top: 10px !important;
    font-size: 14px !important;
}
#bv-mboxzone-lightbox.bv-cv2-cleanslate .bv-submission .bv-fieldsets li label.bv-radio-wrapper-label {
  font-size: 15px!important;
  background-color: #f0f0f0!important;
}
#ratings-summary > div.bv_stars_component_container > svg {
  padding-right: 5px !important;
}







@media screen and (min-width: 736px) {
  .bv-cv2-cleanslate .bv-section-summary-table .bv-section-summary-inline.bv-flex-container-responsive {
      display: -ms-flexbox!important;
      display: flex!important;
  }
}

@media screen and (min-width: 1024px) {
  #BVRRContainer .bv-cv2-cleanslate .bv-header .bv-action-bar { 
    margin-top: -100px !important;
    background-color: transparent !important;
  }
}

@media screen and (max-width: 1280px) {
  #BVRRContainer .bv-cv2-cleanslate .bv-section-summary-table.bv-table-full-width .bv-section-summary-inline.bv-flex-container-responsive {
    width: 100% !important;
  }
}

@media screen and (max-width: 1024px) {
  #BVRRContainer .bv-cv2-cleanslate .bv-header .bv-action-bar {
    float: left !important;
      margin-left: 0 !important;
      width: auto !important;
  }
}


@media screen and (max-width: 800px) {
  .bv-cv2-cleanslate .bv-core-container-361 .bv-write-review-container {
    float: left !important;
  }
  #BVRRContainer .bv-cv2-cleanslate .bv-section-summary-table .bv-section-summary-inline.bv-flex-container-responsive {
    display: block !important;
  }
  #BVRRContainer .bv-cv2-cleanslate .bv-core-container-361 .bv-section-summary .bv-section-summary-block,
  #BVRRContainer .bv-cv2-cleanslate .bv-core-container-361 .bv-section-summary .bv-section-summary-inline .bv-secondary-rating-summary {
      width: 100%!important;
      display: block!important;
      overflow: hidden!important;
      zoom: 1!important;
  }
  #BVRRContainer .bv-cv2-cleanslate .bv-write-review-container {
    width: auto !important;
  }
  #BVRRContainer .bv-cv2-cleanslate .bv-section-summary-inline .bv-secondary-rating-summary {
      clear: both !important;
  }
  #BVRRContainer .bv-cv2-cleanslate .bv-header .bv-action-bar {
      margin-left: 5rem !important;
  }
  #BVRRContainer .bv-cv2-cleanslate .bv-action-bar .bv-write-container {
    float: right !important;
  }
  .reviews-column [data-bv-show=rating_summary] .bv_main_container .bv_stars_component_container, 
  .reviews-column [data-bv-show=rating_summary] .bv_main_container #ratings-summary.bv_main_container_row_flex {
    padding-right: 0.4em !important;
  }
  .reviews-column [data-bv-show=rating_summary] .bv_main_container .bv_main_container_row_flex {
     padding-right: 0!important;
  }

  #bv-mboxzone-lightbox #bv-mbox-lightbox-list.bv-mbox .bv-fieldsets .bv-fieldset .bv-fieldset-inner {
    padding: 0 20px!important;
  }
  #bv-mboxzone-lightbox #bv-mbox-lightbox-list.bv-mbox .bv-fieldsets .bv-fieldset .bv-fieldset-inner .bv-fieldset-label-wrapper {
      padding: 0 35px 0 0!important;
  }
  #BVRRContainer .bv-cv2-cleanslate .bv-header {
    display: none !important;
  }
  #BVRRContainer .bv-cv2-cleanslate .bv-content-list-container .bv-content-placeholder {
    padding-left: 2rem!important;
    padding-right: 2rem!important;
    padding-bottom: 20px!important;
  }
  #BVRRContainer .bv-cv2-cleanslate .bv-content-placeholder button.bv-write-review-label.bv-text-link {
    float: none!important;
    margin: auto!important;
  }
  #BVRRContainer .bv-cv2-cleanslate .bv-content-placeholder .bv-rating-stars-container.bv-rating-none {
    margin-bottom: 35px!important;
  }
}

@media screen and (max-width: 480px) {
  [data-bv-show=rating_summary] .bv_main_container .bv_button_buttonMinimalist, .reviews-column [data-bv-show=rating_summary] .bv_main_container .bv_button_buttonMinimalist {
    text-decoration: underline !important;
  }

  .bv-content-item.bv-stub-content-item.bv-focusable .bv-content-title {
    margin-bottom: 20px !important;
  }

  #BVRRSearchContainer .bv-cleanslate.bv-cv2-cleanslate .bv-masthead h3.title-bye {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  #BVRRContainer .bv-cv2-cleanslate .bv-content-list-container h3.title-bye {
    margin-right: 2rem !important;
    margin-left: 2rem !important;
  }

  #BVRRContainer .bv-cv2-cleanslate .bv-content-pagination .bv-content-pagination-container{
      margin-right: 3rem !important;
      margin-left: 3rem !important;
  }
  #BVRRContainer .bv-cv2-cleanslate .bv-action-bar .bv-cv2-cleanslate h3.title-bye {
    margin-right: 17px !important;
    margin-left: 17px !important;
  }
  #BVRRContainer .bv-cv2-cleanslate .bv-content-pagination-container .bv-content-btn,
  #BVRRContainer .bv-cv2-cleanslate ol,
  #BVRRContainer .bv-cv2-cleanslate .bv-control-bar.bv-control-bar-filter-offset,
  #BVRRContainer .bv-cv2-cleanslate .bv-section-summary,
  #BVRRSearchContainer .bv-cv2-cleanslate .bv-content-search .bv-masthead .bv-masthead-product, .bv-cv2-cleanslate .bv-generic-submission .bv-masthead .bv-masthead-product {
      padding-left: 3rem !important;
      padding-right: 3rem !important;
  }
  #BVRRContainer .bv-content-summary-body-text,
  #BVRRSearchContainer .bv-cv2-cleanslate .bv-core-container-361 .bv-stars-container .bv-rating-ratio {
    width: 100% !important;
  }
  #BVRRContainer .bv-cv2-cleanslate button.bv-write-review {
    margin: 0 auto !important;
  }
  .bv-cv2-cleanslate .bv-write-review-container::before {
      padding-top: 0;
      width: 100% !important;
      text-align: center !important;
      padding-bottom: 10px;
  }
  #BVRRContainer .bv-cv2-cleanslate .bv-dropdown-target {
      padding: 10px 0 10px 0!important;
  }
  #BVRRContainer .bv-cv2-cleanslate .bv-content-data {
    flex-wrap: wrap;
  }
  #BVRRContainer .bv-cv2-cleanslate .bv-content-media-container {
      max-width: 480px !important;
      position: relative !important;
      bottom: 0 !important;
      width: 100% !important;
      margin-bottom: 10px !important;
  }
  #BVRRContainer .bv-cv2-cleanslate .bv-content-media-container .bv-media-item {
    float: left !important;
  }
  #BVRRContainer .bv-cv2-cleanslate .bv-flex-container-column {
    width: 100% !important;
  }
  #BVRRContainer .bv-cv2-cleanslate .bv-action-bar .bv-write-container {
    float: right !important;
  }
}
`;

export default bazaarVoice;
