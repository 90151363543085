import React, { useContext, useState } from 'react';
import createContainer from 'constate';
import { nameHOC } from './hocutils';
import { setPromotionalProductToAdd } from './CartHelpers';
import {
  addProduct,
  removeProduct,
  addCollection,
  removeCollection,
  initCartFromPersistance,
  setWarrantyAndServices
} from './cart';
import { is3pSeller } from './urlHelper';
import _ from './LodashImports';

function useCartContext() {
  const [cart, setCart] = useState({
    bundleItems: []
  });
  const [showCart, setShowCart] = useState(false);
  const [isSizeSelected, setSizeSelection] = useState(false);
  const [isVariantSelectionVisible, setVariantSelectionVisible] = useState(
    true
  );
  const [
    quantityWhileVariantSelectionVisible,
    setQuantityWhileVariantSelectionVisible
  ] = useState(null);
  const [selectedOperatorId, setSelectedOperatorId] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [warrantyOptionCurrent, setWarrantyOptionCurrent] = useState(null);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showBuyNowButton, setShowBuyNowButton] = useState(false);

  const setWarrantyOptionMethod = (payload) => {
    return setWarrantyAndServices({
      ...payload,
      channel: 'warranty'
    });
  };

  const setAssemblyOptionMethod = (payload) => {
    return setWarrantyAndServices({
      ...payload,
      channel: 'service'
    });
  };
  const isBuyNowApplicable = (toggles, sellerId) => {
    if (_.get(toggles, 'isBuyNowEnabled', false) && is3pSeller(sellerId)) {
      return !_.get(toggles, 'isCatalystCheckoutFor3P', false);
    }
    return false;
  };

  const checkBuyNowStatus = ({
    DDMRutHash = '',
    DDMAddresses = 'false',
    DDMPayments = 'false',
    isOneClickCheckoutEnabled = false,
    product
  } = {}) => {
    setShowBuyNowButton(
      isOneClickCheckoutEnabled &&
        Boolean(DDMRutHash) &&
        DDMAddresses === 'true' &&
        DDMPayments === 'true' &&
        !product.isOutOfStock &&
        !(product.warrantyOptions || product.serviceOptions)
    );
  };

  return {
    cart: {
      ...cart,
      isSizeSelected,
      setSizeSelection,
      isVariantSelectionVisible,
      setVariantSelectionVisible,
      quantityWhileVariantSelectionVisible,
      setQuantityWhileVariantSelectionVisible,
      selectedSize,
      setSelectedSize,
      selectedOperatorId,
      setSelectedOperatorId,
      initCartFromPersistance: initCartFromPersistance({ cart, setCart }),
      addProduct: addProduct({
        cart,
        setCart,
        setShowCart,
        setVariantSelectionVisible,
        setQuantityWhileVariantSelectionVisible
      }),
      removeProduct: removeProduct({
        cart,
        setCart,
        setShowCart
      }),
      setWarrantyOption: setWarrantyOptionMethod({
        cart,
        setCart
      }),
      addCollection: addCollection({
        cart,
        setCart,
        setShowCart,
        setVariantSelectionVisible,
        setQuantityWhileVariantSelectionVisible
      }),
      removeCollection: removeCollection({
        cart,
        setCart,
        setShowCart
      }),
      setPromotionalProductToAdd: setPromotionalProductToAdd({ cart, setCart }),
      setAssemblyOption: setAssemblyOptionMethod({ cart, setCart }),
      showCart,
      setShowCart,
      warrantyOptionCurrent,
      setWarrantyOptionCurrent,
      showLoginForm,
      setShowLoginForm,
      isBuyNowApplicable,
      checkBuyNowStatus,
      showBuyNowButton
    }
  };
}

const CartContext = createContainer(useCartContext);

const withCart = (WrappedComponent) => {
  const { getInitialProps } = WrappedComponent;
  const ComponentWithCartCtx = (props) => {
    const { cart } = useContext(CartContext.Context);

    return <WrappedComponent {...props} cart={cart} />;
  };
  if (typeof getInitialProps === 'function') {
    ComponentWithCartCtx.getInitialProps = getInitialProps;
  }
  ComponentWithCartCtx.originalName = nameHOC(WrappedComponent);
  ComponentWithCartCtx.displayName = nameHOC(
    WrappedComponent,
    'WithCartContext'
  );

  return ComponentWithCartCtx;
};

export default CartContext;
export { withCart };
