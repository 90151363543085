import css from 'styled-jsx/css';
import { colors } from '../../config/styles';

const ComparativeTableStyles = css`
  .comparative-table-wrapper {
    margin: 10px 0;
    padding: 20px 0 0;
    transition: all 0.5s ease-in;
  }
  .comparative-table-heading {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    padding: 20px 0 0 12px;
    background-color: ${colors.white.shade1};
    color: #333;
    @mixin tabletToDesktop {
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      padding: 22px 0 0 31px;
    }

    @mixin desktop {
      border-radius: 12px 12px 0 0;
    }
  }
`;

export { ComparativeTableStyles };
