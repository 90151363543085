import React, { useState } from 'react';
import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import PropTypes from 'prop-types';
import { WarrantySelectStyles } from './WarrantySelect.style';
import noop from '../../../utils/noop';
import Warning from '../../Warning/Warning';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import _ from '../../../utils/LodashImports';

const WarrantySelect = ({
  defaultSelectedIndex,
  deviceType,
  errorMessage,
  handleWarrantyOptionsSelected,
  isRebrandingEnabled,
  options,
  inPdp,
  theme,
  appCtx
}) => {
  const themedStyles = WarrantySelectStyles(theme);
  const [selectedIndex, setSelectedIndex] = useState(defaultSelectedIndex);
  const setSelectedOptionClass = (index) => index === selectedIndex;
  const marketplaceClass = isRebrandingEnabled ? 'mkp' : '';
  const fallbackWarrantyBenefits = [
    {
      header: 'Reparaciones con repuestos originales',
      renderCross: false,
      firstColRenderCross: false
    },
    {
      header: 'Producto de reemplazo durante reparación.*',
      renderCross: true,
      firstColRenderCross: true
    },
    {
      header: 'Reparación dentro de 12 a 18 días',
      renderCross: true,
      firstColRenderCross: true
    },
    {
      header: 'Cobertura en caso de variación de voltaje',
      renderCross: true,
      firstColRenderCross: true
    },
    {
      header: 'Garantía transferible si se vende o regala el producto',
      renderCross: false,
      firstColRenderCross: true
    }
  ];
  const warrantyBenefits = _.get(
    appCtx,
    'siteConfig.configurations.warrantyBenefits',
    fallbackWarrantyBenefits
  );
  const warranties = options
    .filter((option) => option.offeringId || option.value)
    .map((warranty) => {
      const { label, price } = warranty;
      let textPrice = price;
      let textLabel = label;

      if (label.includes('S/')) {
        textLabel = `${label.split('S/')[0].trim()}`;
        textPrice = `S/ ${label.split('S/')[1].trim()}`;
      }

      if (label.includes('$')) {
        textLabel = `${label.split('$')[0].trim()}`;
        textPrice = `$ ${label.split('$')[1].trim()}`;
      }

      if (textLabel.split(' ').length <= 2) {
        textLabel = `${textLabel} de Garantía Extendida`;
      }

      return {
        ...warranty,
        textPrice,
        label: textLabel
      };
    });

  const inPageText = inPdp ? 'inPDP' : 'inXLP';
  const centerOptions = warranties.length < 3 ? 'center-options' : '';
  const isMobile = deviceType === 'mobile';
  const widthClass = warranties.length < 3 ? {} : { width: '100%' };
  const handleOptionChange = (index) => {
    if (selectedIndex !== index) {
      handleWarrantyOptionsSelected(options[index]);
      setSelectedIndex(index);
    }
  };

  let bottomStyle = warranties.length < 2 ? { marginRight: '132px' } : {};
  bottomStyle = warranties.length === 2 ? { marginRight: '72px' } : bottomStyle;
  bottomStyle = isMobile ? {} : bottomStyle;
  const noWarranty = options.filter((option) => !option.offeringId);
  let firstOptionLabel = 'No, gracias';

  if (noWarranty.length === 1) {
    firstOptionLabel = noWarranty[0].label;
  }

  const warrantiesLength = options.length;
  let cartServicesStyle =
    warrantiesLength > 4
      ? { width: '780px', minWidth: '660px' }
      : { width: '100%', minWidth: '660px' };
  cartServicesStyle =
    isMobile && warrantiesLength === 2
      ? { ...cartServicesStyle, minWidth: '400px' }
      : cartServicesStyle;

  cartServicesStyle =
    isMobile && warrantiesLength === 3
      ? { ...cartServicesStyle, minWidth: '526px' }
      : cartServicesStyle;

  return (
    <div
      className={`warrantyOptions ${centerOptions} ${
        isMobile ? 'warrantyOptions-mobile' : ''
      }`}
      style={cartServicesStyle}
    >
      <table style={widthClass}>
        <thead>
          <tr>
            <th className="warrantyOptions__header">
              <i className="csicon-shield" />
            </th>
            <th className={`warrantyOptions__right-border ${marketplaceClass}`}>
              <p>Sin Garantía Extendida</p>
              <span>(Solo garantía de fabricante )</span>
            </th>
            {warranties.map((warranty, index) => (
              <th
                className={`${
                  index === 0 ? 'warrantyOptions__recommended' : ''
                } ${marketplaceClass}`}
                key={warranty.offeringId}
              >
                {index === 0 && (
                  <p
                    className={`warrantyOptions__recommended-text ${marketplaceClass}`}
                  >
                    ¡Recomendado!
                  </p>
                )}
                <p className="warrantyOptions__header-text">{warranty.label}</p>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {/* Warranty prices row */}
          <tr>
            <td className="warrantyOptions__header">Valor Garantía</td>
            <td
              className={`warrantyOptions__body warrantyOptions__right-border ${marketplaceClass}`}
            >
              0
            </td>
            {warranties.map((warranty, index) => (
              <td
                className={`warrantyOptions__body ${
                  index === 0 ? 'warrantyOptions__recommended' : ''
                } warrantyOptions__prices ${marketplaceClass}`}
                key={warranty.offeringId}
              >
                {warranty.textPrice}
              </td>
            ))}
          </tr>

          {warrantyBenefits.map((row, rowIndex) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={`tr-warrantyOptions-${rowIndex}`}>
              <td className="warrantyOptions__header">{row.header}</td>
              <td
                className={`warrantyOptions__body warrantyOptions__right-border ${marketplaceClass}`}
              >
                {row.specificText ? (
                  row.specificText
                ) : (
                  <div
                    className={
                      row.firstColRenderCross
                        ? 'warrantyOptions__cross'
                        : `warrantyOptions__check ${marketplaceClass}`
                    }
                  />
                )}
              </td>
              {warranties.map((warranty, index) => (
                <td
                  className={`warrantyOptions__body ${
                    index === 0 ? 'warrantyOptions__recommended' : ''
                  } ${marketplaceClass}`}
                  key={warranty.offeringId}
                >
                  <div
                    className={
                      row.renderCross
                        ? 'warrantyOptions__cross'
                        : `warrantyOptions__check ${marketplaceClass}`
                    }
                  />
                </td>
              ))}
            </tr>
          ))}
          {/* last different row */}
          <tr
            className="warrantyOptions__bottom"
            style={{ ...widthClass, ...bottomStyle }}
            id={`test-id-InCart-${inPageText}-WarrantyOption`}
          >
            <td />
            <td>
              {' '}
              <div
                className={`warrantyOptions__option ${
                  setSelectedOptionClass(warranties.length)
                    ? 'warrantyOptions__option--selected'
                    : ''
                } ${marketplaceClass}`}
                onClick={() => handleOptionChange(warranties.length)}
                id={`testId-InCart-${inPageText}-WarrantyOption-btn-0`}
                aria-hidden="true"
              >
                <span>{firstOptionLabel}</span>
              </div>
            </td>
            {warranties.map((warranty, index) => (
              <td>
                <div
                  className={`warrantyOptions__option ${
                    setSelectedOptionClass(index)
                      ? 'warrantyOptions__option--selected'
                      : ''
                  } ${marketplaceClass}`}
                  onClick={() => handleOptionChange(index)}
                  aria-hidden="true"
                  id={`testId-InCart-${inPageText}-WarrantyOption-btn-${index +
                    1}`}
                  key={warranty.offeringId}
                >
                  <span>
                    Agregar <br />
                    {warranty.label}
                  </span>
                </div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>

      <div className="warrantyOptions_bottomLabel">
        *Aplica a categorías: lavadoras, secadoras, cocinas y refrigeradores
      </div>

      {errorMessage && (
        <Warning label={errorMessage} iconSize="copy2" labelSize="copy5" />
      )}

      <style jsx>{themedStyles}</style>
    </div>
  );
};

WarrantySelect.defaultProps = {
  defaultSelectedIndex: -1,
  deviceType: 'desktop',
  errorMessage: null,
  handleWarrantyOptionsSelected: noop,
  isRebrandingEnabled: false,
  options: [],
  inPdp: false,
  theme: null,
  appCtx: {}
};

WarrantySelect.propTypes = {
  defaultSelectedIndex: PropTypes.number,
  deviceType: PropTypes.string,
  errorMessage: PropTypes.string,
  handleWarrantyOptionsSelected: PropTypes.func,
  isRebrandingEnabled: PropTypes.bool,
  options: PropTypes.array,
  inPdp: PropTypes.bool,
  theme: PropTypes.object,
  appCtx: PropTypes.object
};

export default withApplicationContext(withTheme(WarrantySelect));
