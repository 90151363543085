import css from 'styled-jsx/css';
import { colors } from '../../config/styles';

const ProductCarouselStyles = css`
  .products-carousel-wrapper {
    margin: 10px 0;
    padding: 20px 0 0;
    transition: all 0.5s ease-in;
  }
  .products-carousel-heading {
    letter-spacing: 0;
    line-height: 3rem;
    font-weight: 500;
    padding: 1rem 4rem;
    border-bottom: thin solid ${colors.gray.shade14};
    background-color: ${colors.white.shade1};
    display: flex;
    justify-content: space-between;
    align-items: center;
    .products-carousel-sub-heading {
      color: ${colors.gray.shade44};
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      letter-spacing: 1px;
      @mixin mobileToTablet {
        color: ${colors.gray.shade48};
      }
    }
    @mixin mobileToTablet {
      font-size: 1.5625rem;
      padding: 1rem 0 1rem 1.5rem;
      line-height: 2.2rem;
      display: block;
    }

    @mixin desktop {
      border-radius: 12px 12px 0 0;
    }
  }
`;

export { ProductCarouselStyles };
