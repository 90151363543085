import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import { isSodimacStandalone } from '@digital-retail/store-manager';
import { linkButtonStyle } from './LinkButton.style';
import { withApplicationContext } from '../../../utils/ApplicationContext';

const LinkButton = ({
  children,
  type,
  href,
  size,
  disabled,
  target,
  id,
  theme,
  appCtx
}) => {
  const themedLinkButtonStyles = linkButtonStyle(theme);
  const url = disabled ? '#' : href;
  return (
    <a
      id={id}
      href={url}
      className={`button button-${type} button-${size} ${
        disabled ? 'button-disabled' : ''
      }${isSodimacStandalone(appCtx.store) ? 'so_com' : ''}`}
      target={target}
    >
      {children}
      <style jsx>{themedLinkButtonStyles}</style>
    </a>
  );
};

const LinkLabel = ({
  children,
  type,
  href,
  size,
  disabled,
  target,
  id,
  theme
}) => {
  const themedLinkButtonStyles = linkButtonStyle(theme);
  const url = disabled ? '#' : href;
  return (
    <a
      href={url}
      className={`link-button button-${type} button-${size} ${
        disabled ? 'button-disabled' : ''
      }`}
      target={target}
      id={id}
    >
      {children}
      <style jsx>{themedLinkButtonStyles}</style>
    </a>
  );
};

LinkLabel.defaultProps = {
  children: null,
  type: 'primary',
  href: '#',
  size: '',
  disabled: false,
  target: '',
  id: ''
};

LinkLabel.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'quaternary',
    'default'
  ]),
  href: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  target: PropTypes.string,
  id: PropTypes.string,
  theme: PropTypes.object.isRequired
};

LinkButton.defaultProps = {
  children: null,
  type: 'primary',
  href: '#',
  size: 'medium',
  disabled: false,
  target: '',
  id: 'linkButton'
};

LinkButton.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'quaternary',
    'rebranding'
  ]),
  href: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  target: PropTypes.string,
  id: PropTypes.string,
  theme: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired
};

const LinkLabelWithTheme = withTheme(LinkLabel);
export { LinkLabelWithTheme as LinkLabel };

export default withApplicationContext(withTheme(LinkButton));
