import React from 'react';
import PropTypes from 'prop-types';

const OkayToShopInfo = ({ okayToShopBarcodes }) => {
  return <div id="OKTS_div" data-ean={okayToShopBarcodes.join(',')} />;
};

OkayToShopInfo.propTypes = {
  okayToShopBarcodes: PropTypes.array.isRequired
};

export default OkayToShopInfo;
