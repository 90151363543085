import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { LoginModal } from '@digital-retail/falabella-ui-cross-components';
import { withMyLists } from '../../utils/MyListsContext';
import useMyLists from './useMyLists';
import styles from './MyLists.style';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import constants from '../../config/constants';
import { withUser } from '../../utils/UserContext';

const MyListModal = dynamic(() => import('./Modal/MyListsModal'), {
  ssr: false,
  loading: () => ''
});

const MyLists = ({
  appCtx,
  myLists,
  product,
  labels,
  className,
  isPDPpage,
  isUserLoggedIn,
  refreshUserData
}) => {
  const isMyListsEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isPDPMyListsEnabled',
    false
  );
  const { myListMetrics } = myLists;
  const { setIsModalClicked, setIsCreateListClicked } = myListMetrics;
  const { CLICK_ML } = constants.ML_METRICS.METRICS_DATA;
  const [modalVisible, setModalVisible] = React.useState(false);
  const [showLoginForm, setShowLoginForm] = React.useState(false);
  const {
    createList,
    lists,
    onListChange,
    onSaveLists,
    validateList,
    error,
    loading,
    setShowForm,
    isSaveButtonEnabled,
    showForm,
    productIsInAnyList
  } = useMyLists({
    myLists,
    product,
    labels,
    isPDPpage,
    setShowLoginForm,
    setModalVisible
  });
  const l = {
    defaultMyList: labels.DAFAULT_LIST_TYPE || 'WISH_LIST',
    save: labels.SAVE || 'Guardar',
    inputPlaceHolder: labels.MY_LIST_INPUT_PLACEHOLDER || 'Ej. Compra del mes',
    formTitle: labels.INPUT_NEW_LIST_NAME || 'Ingresa un nombre para tu lista',
    myLists: labels.MY_LISTS || 'Mis listas',
    createNewList: labels.CREATE_A_NEW_LIST_MY_LISTS || 'Crear una nueva lista',
    addToList: labels.ADD_TO_A_LIST_MY_LISTS || 'Agrega a una lista'
  };
  const handleSetModalVisible = () => {
    if (isUserLoggedIn) {
      setModalVisible(true);
      setIsModalClicked(CLICK_ML);
    } else {
      setShowLoginForm(true);
    }
  };
  const atgBaseUrl = _.get(
    appCtx,
    'siteConfig.endpoints.ATG_AUTH_BASEURL',
    'https://www.falabella.com'
  );
  const catalystBaseUrl = _.get(
    appCtx,
    'siteConfig.endpoints.CATALYST_AUTH_BASEURL',
    'https://www.falabella.com'
  );
  const classHasLists = productIsInAnyList ? 'active' : '';
  const onClose = () => {
    setShowLoginForm(false);
  };

  const onUserLogin = () => {
    refreshUserData();
    setModalVisible(true);
  };
  return (
    <React.Fragment>
      {isMyListsEnabled && (
        <div className={`container-my-lists ${className}`}>
          <button
            type="button"
            id="myListIcon"
            onClick={() => handleSetModalVisible()}
          >
            <i className={`csicon-my-list ${classHasLists}`} />
          </button>
          <MyListModal
            myLists={lists}
            toggleState={{
              visible: modalVisible,
              setVisible: setModalVisible
            }}
            formState={{
              visible: showForm,
              setVisible: setShowForm,
              setIsClickedMetric: setIsCreateListClicked
            }}
            createList={createList}
            onSaveLists={onSaveLists}
            appCtx={appCtx}
            onListChange={onListChange}
            validateList={validateList}
            error={error}
            loading={loading}
            labels={l}
            isSaveButtonEnabled={isSaveButtonEnabled}
          />
          <style jsx>{styles}</style>
        </div>
      )}
      <LoginModal
        id="showLoginForm"
        isRebrandingEnabled={appCtx.isRebrandingEnabled}
        atgBaseUrl={atgBaseUrl}
        catalystBaseUrl={catalystBaseUrl}
        onClose={onClose}
        onUserLogin={onUserLogin}
        tenant={appCtx.regionCode}
        showLoginForm={showLoginForm}
      />
    </React.Fragment>
  );
};

MyLists.propTypes = {
  appCtx: PropTypes.object.isRequired,
  myLists: PropTypes.object.isRequired,
  isPDPpage: PropTypes.bool,
  product: PropTypes.objectOf({
    productId: PropTypes.string.isRequired,
    variantId: PropTypes.string.isRequired,
    offeringId: PropTypes.string.isRequired,
    sellerId: PropTypes.string
  }).isRequired,
  labels: PropTypes.object,
  className: PropTypes.string,
  refreshUserData: PropTypes.func.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired
};

MyLists.defaultProps = {
  labels: {},
  className: '',
  isPDPpage: false
};

export { MyLists };
export default withUser(withLabels(withMyLists(MyLists)));
