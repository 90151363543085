/* eslint-disable camelcase */
/* eslint-disable no-irregular-whitespace */
import constants from '../../../config/constants';
import _ from '../../../utils/LodashImports';

const getContent = (tenant, textDictionary, store) => {
  const isSodimacStandAlone = store === constants.STORES.so_com;
  const isTocom = store === constants.STORES.to_com;
  const modalTitle_1 = _.get(
    textDictionary,
    'RETURN_POLICY_MODAL_HEADER_1',
    'Derecho a retracto'
  );
  const para_1_1 = _.get(
    textDictionary,
    'RETURN_POLICY_PARA_1_1',
    `Por ley, tienes hasta <b style="font-weight: bold">10 días para devolver un producto</b> si te arrepientes de la compra.`
  );
  const para_1_2 = _.get(
    textDictionary,
    'RETURN_POLICY_PARA_1_2',
    `Ten en cuenta que lo debes haber comprado por internet y que hay ciertas categorías que no tienen este derecho:`
  );

  const list_1 = _.get(
    textDictionary,
    'RETURN_POLICY_LIST_1',
    `Productos que, por su naturaleza no puedan ser devueltos, puedan deteriorarse o caducar con rapidez.*Confeccionados a la medida.*De uso personal.`
  );
  const modalTitle_2 = _.get(
    textDictionary,
    'RETURN_POLICY_MODAL_HEADER_2',
    'Satisfacción garantizada'
  );
  const para_2_1 = _.get(
    textDictionary,
    'RETURN_POLICY_PARA_2_1',
    `En falabella.com te damos <b style="font-weight: bold">30 días desde que recibes el producto</b>. Debe estar en perfecto estado, con todas sus etiquetas y sin uso, tal como te lo entregamos.`
  );
  const para_2_1_so_com = _.get(
    textDictionary,
    'SO_COM_RETURN_POLICY_PARA_2_1',
    `En sodimac.cl te damos <b style="font-weight: bold">30 días desde que recibes el producto</b>. Debe estar en perfecto estado, con todas sus etiquetas y sin uso, tal como te lo entregamos.`
  );
  const para_2_2 = _.get(
    textDictionary,
    'RETURN_POLICY_PARA_2_2',
    `Ten en cuenta que hay productos que <b style="font-weight: bold">no puedes devolver si te arrepientes de la compra:</b>`
  );

  const list_2 = _.get(
    textDictionary,
    'RETURN_POLICY_LIST_2',
    `Productos digitales que se entregan a través de una descarga electrónica, por ejemplo, cupones de experiencia o programas para el computador.*Productos a pedido o confeccionados a medida.*Productos que han sido informados como imperfectos, usados, reparados, abiertos, de segunda selección, remanufacturados o con alguna deficiencia, que sean comprados en esa condición a un precio reducido.*Alimentos, bebidas, medicamentos, suplementos alimenticios, vitaminas, entre otros análogos.*Pinturas de un color a solicitud.*Plantas.`
  );
  const modalTitle_3 = _.get(
    textDictionary,
    'RETURN_POLICY_MODAL_HEADER_3',
    'Satisfacción garantizada:'
  );
  const para_3_1 = _.get(
    textDictionary,
    'RETURN_POLICY_PARA_3_1',
    `<b style="font-weight: bold">Deben estar cerrados, con todos sus sellos y etiquetas</b>`
  );
  const para_3_3 = _.get(
    textDictionary,
    'RETURN_POLICY_PARA_3_3',
    `No aplica para productos de uso personal, confeccionados a medida, celulares, alimentos, bebidas, medicamentos, suplementos alimenticios y vitaminas.`
  );
  const modalTitle_4 = _.get(
    textDictionary,
    'RETURN_POLICY_MODAL_HEADER_4',
    'Garantía legal:'
  );

  const para_4_1 = _.get(
    textDictionary,
    'RETURN_POLICY_PARA_4_1',
    `<b style="font-weight: bold">No tienen devolución o cambio si te arrepientes de la compra</b>`
  );

  const list_3 = _.get(
    textDictionary,
    'RETURN_POLICY_LIST_3',
    `Cuidado personal, belleza, higiene y salud, productos de bienestar sexual.​​*Trajes de baño, fajas, ropa interior.​​​*Electrónica.​​​*Electro hogar.​​*Almohadas y ropa de cama.​*Muebles armables y pinturas.​​​*Alimentos y cuidado de mascotas.​​​*Productos de limpieza (PGC no comestible).​*Asiento de inodoro.*Toallas de baño y playa.`
  );

  const list_4 = _.get(
    textDictionary,
    'RETURN_POLICY_LIST_4',
    `Alimentos y bebidas. ​*Productos digitales (descarga inmediata).​​​*De segunda mano o reacondicionados.​​​*Productos hechos a medida.​​​*Pinturas color a pedido.​​​*Medicamentos, vitaminas y suplementos.​​​*Plantas​​.*Productos que hayan sido previamente instalados.*Baterías de auto.`
  );

  if (tenant === 'pe') {
    return [
      {
        title: modalTitle_1,
        para1: para_1_1,
        para2: para_1_2,
        list: ''
      },
      {
        title: '',
        para1:
          (isSodimacStandAlone ? para_2_1_so_com : para_2_1) ||
          `<b style="font-weight: bold">Otros plazos para devolución y cambio</b>`,
        para2: '',
        list:
          list_2 ||
          `<b style="font-weight: bold">48 horas</b>: cemento, mezclas de: hormigón, morteros, yeso y otros productos para asfalto, hormigón, albañilería.
              *<b style="font-weight: bold">7 días</b>: electrodomésticos, tecnología, línea blanca, colchones, muebles, bicicletas y máquinas de ejercicio.`
      },
      {
        title: '',
        para1: para_3_1,
        para2: '',
        list: list_3
      },
      {
        title: '',
        para1: para_4_1,
        para2: '',
        list: list_4
      }
    ];
  }

  if (tenant === 'co') {
    return [
      {
        title: modalTitle_1,
        para1: para_1_1,
        para2: para_1_2,
        list: ''
      },
      {
        title: modalTitle_2,
        para1: para_2_1,
        para2: para_1_2,
        list: '',
        para2Icon: true
      },
      {
        title: modalTitle_3,
        para1: '',
        para2: '',
        list: list_3,
        para3: para_3_3,
        para2Icon: true
      },
      {
        title: modalTitle_4,
        para1: para_4_1,
        para2: '',
        list: ''
      }
    ];
  }

  if (isTocom) {
    return [
      {
        title: modalTitle_1,
        para1: null,
        para2: null,
        list: null,
        para2Icon: false,
        para3: null,
        para3Icon: false
      },
      {
        title: modalTitle_3,
        para1: para_1_1,
        para2: para_1_2,
        list: list_1,
        para2Icon: false,
        para3: para_3_3,
        para3Icon: false
      },
      {
        title: modalTitle_2,
        para1: para_2_1,
        para2: para_2_2,
        para3: null,
        list: null,
        para2Icon: false,
        para3Icon: false
      },
      {
        title: null,
        para1: para_3_1,
        para2: null,
        list: list_2,
        para2Icon: false,
        para3: null,
        para3Icon: false
      },
      {
        title: null,
        para1: para_4_1,
        para2: null,
        list: list_4,
        para2Icon: false,
        para3: null,
        para3Icon: false
      }
    ];
  }

  return [
    {
      title: modalTitle_1,
      para1: para_1_1,
      para2: para_1_2,
      list: list_1,
      para2Icon: true
    },
    {
      title: modalTitle_2,
      para1: isSodimacStandAlone ? para_2_1_so_com : para_2_1,
      para2: para_2_2,
      list: list_2,
      para2Icon: true
    }
  ];
};

export { getContent };
