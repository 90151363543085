const okToShopInfoStylesCss = `
/* Based on https://simplegrid.io/ and https://hacks.mozilla.org/2017/04/replace-bootstrap-layouts-with-css-grid/ */

@import url(https://fonts.googleapis.com/css?family=Lato:400,300,300italic,400italic,700,700italic);

/* RESET */
#OKTS_div div, #OKTS_div span, #OKTS_div p,
#OKTS_div h1, #OKTS_div h2, #OKTS_div h3, #OKTS_div h4, #OKTS_div h5, #OKTS_div h6,
#OKTS_div a, #OKTS_div em, #OKTS_div img, #OKTS_div path,
#OKTS_div table, #OKTS_div tbody, #OKTS_div tr, #OKTS_div td{
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
  vertical-align: baseline;
  line-height: 1.5;
}

#OKTS_div{
  font-family: 'Lato', Helvetica, sans-serif;
  color: #333447;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  /* padding-left: 15px;
  padding-right: 15px; */
  /* background-color: #fff; */
}

#OKTS_div .main_div{
  display: inline-block;
  vertical-align: top;
}

#OKTS_div .warnings_icons{
  width: 80px;
  height: 80px;
  margin: .1em;
}

#OKTS_div .certificate{
  display: inline-block;
  width: 80px;
  vertical-align: top;
  margin-top: .4em;
  margin-bottom: .4em;
  margin-left: .4em;
  margin-right: .4em;
}

#OKTS_div .certificate p{
  font-weight: 300;
  color: #777;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px !important;
  line-height: 1;
}

#OKTS_div .certificate img{
  width: 50px;
  height: 50px;
  margin: .1em;
}

/* TYPOGRAPHY */

#OKTS_div em {
    font-style: italic;
}

#OKTS_div h1 {
  font-size: 14px !important;
}

#OKTS_div h2 {
  font-size: 2rem;
}

#OKTS_div h3 {
  font-size: 18px !important;
  font-weight: 700 !important;
}

#OKTS_div h4 {
 font-size: 18px !important;
}

#OKTS_div h5 {
  font-size: 14px !important;
}

#OKTS_div h6 {
  font-size: 14px !important;
}

#OKTS_div p {
  font-size: 12px;
  font-weight: 200;
  line-height: 1.8;
}

#OKTS_div p.center {
  display: flex !important;
}

#OKTS_div .font-light {
  font-weight: 300;
}

#OKTS_div .font-regular {
  font-weight: 400;
}

#OKTS_div .gray{
  color: #888;
}

#OKTS_div div.center.gray{
  text-align: center !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

/* POSITIONING */

#OKTS_div .left {
  text-align: left;
}

#OKTS_div .right {
  text-align: right;
  width:50% !important;
}

#OKTS_div .center {
  margin-left: auto;
  margin-right: auto;
  text-align: unset !important
}

#OKTS_div .justify {
  text-align: justify;
}

/* TABLES */

#OKTS_div table{
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  width: 100%;
}

#OKTS_div td{
  border: 1px solid #000;
  margin: 0px;
  padding: 4px;
  text-align: left;
  font-size: 12px !important;
}

#OKTS_div td.right{
  width: 20%;
}

#OKTS_div tr .strong_border{
  border-bottom: 4px solid #000;
}`;

export { okToShopInfoStylesCss };
