import apiConfig from '../../config/api/apiConfig';
import httpService from '../httpService';
import _ from '../LodashImports';
import Logger from '../Logger';
import CookieStorage from '../CookieStorage';
import { getCommerceHeaders } from '../tenantHelper';

import {
  cartAlertsAndErrorSeggregator,
  mapProduct,
  segregateCollection
} from './helpers';
import { isSodimacSite } from '../urlHelper';

const addCollection = ({
  cart,
  setCart,
  setShowCart,
  setVariantSelectionVisible,
  setQuantityWhileVariantSelectionVisible
}) => async ({
  appCtx,
  products = [],
  mainProduct = [],
  isTypeC = false,
  replaceCartItems = false,
  offeringId = ''
}) => {
  const { regionCode, store, politicalId, priceGroupId } = appCtx;
  const isSocom = store === 'so_com';

  const combinedProduct = [...products, ...mainProduct];

  const cartId = CookieStorage.getCookie('cartId') || _.get(cart, 'cartId', '');
  const {
    OFFER_PRICE_LABEL,
    NORMAL_PRICE_LABEL,
    A2C_CONTINUE_SHOPPING = '',
    A2C_VIEW_BASKET = '',
    A2C_EXT_WARRANTY = '',
    CART_ASSEMBLY_OPTION_SEC_LABEL = ''
  } = _.get(appCtx, 'siteConfig.textDictionary', {});

  const cartLines = combinedProduct.map(({ skuId, quantity }) => {
    return {
      cartLineNumber: offeringId || skuId,
      item: {
        variantId: skuId,
        offeringId
      },
      quantity: {
        quantityNumber: quantity,
        quantityUnit: 'C/U'
      }
    };
  });

  const reqBody = {
    data: {
      cart: {
        cartLines
      }
    },
    metadata: {
      priceGroup: 'default'
    }
  };

  reqBody.metadata.politicalAreaId = politicalId;
  reqBody.metadata.priceGroup = priceGroupId;

  const additionalHeaders = getCommerceHeaders(regionCode, store, true);

  const query = cartId ? `?cartId=${cartId}` : '';
  const url = apiConfig.addToCartCommerceUrl(query, regionCode);
  const response = await httpService().post(
    url,
    reqBody,
    {
      credentials: 'include'
    },
    additionalHeaders,
    'json'
  );
  const commerceCartId = _.get(response, 'data.data.cart.cartId', '');
  const items = _.get(response, 'data.data.cart.cartLines', []);
  const alerts = _.get(response, 'data.data.cart.cartAlerts', []);
  const { mappedErrors } = cartAlertsAndErrorSeggregator(
    alerts,
    appCtx.siteConfig
  );
  const totalProductsQuantityCount = _.get(
    response,
    'data.data.cart.totalProductsQuantityCount',
    0
  );
  const totalProductsCount = totalProductsQuantityCount;

  const textDictionary = {
    continueShoppingText: A2C_CONTINUE_SHOPPING,
    viewBasketText: A2C_VIEW_BASKET,
    extendedWarrantyLightBoxLinkText: A2C_EXT_WARRANTY,
    CART_ASSEMBLY_OPTION_SEC_LABEL
  };

  const {
    BASKET_PAGE_URI = '',
    EXT_WARRANTY_LIGHTBOX_LINK = '',
    BASKET_PAGE_URI_SODIMAC = ''
  } = _.get(appCtx, 'siteConfig.endpoints', {});
  const toggles = _.get(appCtx, 'siteConfig.toggles', {});

  const endPoints = {
    baskePagetUri: {
      path: BASKET_PAGE_URI
    },
    extendedWarrantyLightBoxLink: {
      path: EXT_WARRANTY_LIGHTBOX_LINK
    }
  };
  if (isSodimacSite()) {
    endPoints.baskePagetUri.path = BASKET_PAGE_URI_SODIMAC;
  }
  if (response.statusCode === 200 && items && !mappedErrors) {
    const mappedProducts = items.map((item) =>
      mapProduct(
        item,
        regionCode,
        { OFFER_PRICE_LABEL, NORMAL_PRICE_LABEL },
        toggles,
        isSocom
      )
    );

    const mappedItems = segregateCollection({
      mappedProducts,
      collection: isTypeC ? cart.typeCCollections : cart.typeACollections,
      action: 'ADD',
      isTypeC,
      products: mainProduct,
      cartItems: replaceCartItems ? [] : cart.cartItems
    });

    setCart({
      ...cart,
      ...mappedItems,
      cartId: commerceCartId,
      textDictionary,
      totalProductsCount,
      endPoints,
      isAtgPrice: false
    });
    setQuantityWhileVariantSelectionVisible(null);
    setVariantSelectionVisible(false);
    setShowCart(true);
  } else {
    if (mappedErrors && mappedErrors.length > 0) {
      return mappedErrors;
    }
    const { errors } = _.get(response, 'data', {});
    Logger.error(
      `Failed to Add Product to Collection ${JSON.stringify(products)}`
    );
    return errors || [];
  }
  return null;
};

export default addCollection;
