import React, { useState, useRef, useEffect } from 'react';
import PropType from 'prop-types';
import { isSodimacStandalone } from '@digital-retail/store-manager';
import { ExpandableSwatchStyle } from './ExpandableSwatch.style';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import { withDigitalData } from '../../utils/DigitalDataContext';
import { withApplicationContext } from '../../utils/ApplicationContext';

const ExpandableSwatch = ({
  children,
  height,
  labels,
  buttonType,
  digitalData,
  appCtx
}) => {
  const ref = useRef();
  const [expanded, setExpanded] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);

  useEffect(() => {
    const shouldExpand =
      ref.current.children[0].getBoundingClientRect().height > height;

    setIsExpandable(shouldExpand);
    setExpanded(!shouldExpand);
  }, [children]);

  const config = expanded
    ? { label: labels.SEE_LESS, icon: 'csicon-arrow_up_small' }
    : {
        label: labels.SEE_MORE,
        icon: 'csicon-arrow_down_small'
      };

  let className = '';
  if (isExpandable) {
    className = expanded ? 'swatch-expanded' : 'swatch-collapsed';
  }

  const calculatedHeight = isExpandable && !expanded ? `${height}px` : 'auto';

  const toggleSwatch = () => {
    if (expanded) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
    setExpanded(!expanded);
  };
  const label = _.get(config, 'label', '');
  if (isExpandable && label === labels.SEE_MORE) {
    digitalData.pdp.setViewMoreButton(true);
  }

  return (
    <div
      ref={ref}
      style={{
        height: `${calculatedHeight}`
      }}
      className={`swatch ${className}`}
    >
      {children}
      {isExpandable && (
        <button
          type="button"
          id={`${className}-id`}
          className={`${buttonType} ${buttonType}-${
            expanded ? 'expand' : 'collapse'
          }Button ${isSodimacStandalone(appCtx.store) ? 'so_com' : ''}`}
          onClick={toggleSwatch}
        >
          <span>{label}</span>
          <span className="icon-right">
            <i className={config.icon} />
          </span>
        </button>
      )}
      <style jsx>{ExpandableSwatchStyle}</style>
    </div>
  );
};

ExpandableSwatch.propTypes = {
  children: PropType.node.isRequired,
  height: PropType.number.isRequired,
  labels: PropType.object.isRequired,
  buttonType: PropType.oneOf('swatchButton', 'mkp-swatchButton', ''),
  digitalData: PropType.object.isRequired,
  appCtx: PropType.object.isRequired
};

ExpandableSwatch.defaultProps = {
  buttonType: 'swatchButton'
};

export { ExpandableSwatch };

export default withApplicationContext(
  withDigitalData(withLabels(ExpandableSwatch))
);
