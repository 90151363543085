import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { withProduct } from '../../utils/ProductContext';
import { withUser } from '../../utils/UserContext';
import { withCart } from '../../utils/CartContext';
import { BazaarVoiceComponent } from '../../components/BazaarVoice/BazaarVoice';
import RatingAndReviewsStyles from './RatingAndReviews.style';
import { withApplicationContext } from '../../utils/ApplicationContext';
import _ from '../../utils/LodashImports';

const RatingStars = dynamic(
  () =>
    import('@digital-retail/rating-and-reviews').then((lib) => lib.RatingStars),
  {
    ssr: false,
    loading: () => ''
  }
);
const LabelToComment = dynamic(
  () =>
    import('@digital-retail/rating-and-reviews').then(
      (lib) => lib.LabelToComment
    ),
  {
    ssr: false,
    loading: () => ''
  }
);

const RatingAndReviews = ({ isUserLoggedIn, product, cart, appCtx }) => {
  const isRatingsShownInPDP = _.get(
    appCtx,
    'siteConfig.toggles.isRatingsShownInPDP',
    true
  );
  if (!isRatingsShownInPDP) {
    return null;
  }

  const isNewBVWriteReviewEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isNewBVWriteReviewEnabled',
    false
  );
  const isInHouseReviewSectionEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isInHouseReviewSectionEnabled',
    false
  );
  const { setShowLoginForm } = cart;

  const isAllowedToReview = () => {
    if (!isUserLoggedIn) {
      setShowLoginForm(true);
    }
  };

  const handleStarsClick = () => {
    const elementId = isInHouseReviewSectionEnabled
      ? 'InHouseReviewsContainer'
      : 'BVRRSearchContainer';
    const element = document.getElementById(elementId);

    if (element) {
      // Calculate the Y coordinate of the element relative to the document
      const elementY = element.getBoundingClientRect().top + window.scrollY;
      // Scroll to the element
      window.scrollTo({
        top: elementY,
        behavior: 'smooth' // You can use 'auto' or 'smooth' for smooth scrolling
      });
    }
  };

  if (isNewBVWriteReviewEnabled) {
    return (
      <div className="rr-wrapper">
        <div
          aria-hidden="true"
          onClick={handleStarsClick}
          className="rr-rating-stars"
          data-testid="rr-rating-stars"
        >
          <RatingStars />
        </div>
        <div
          className="rr-label-to-comment"
          aria-hidden="true"
          onClick={isAllowedToReview}
        >
          <LabelToComment />
        </div>
        <style jsx>{RatingAndReviewsStyles}</style>
      </div>
    );
  }
  // otherwise just return the old BV Hosted component
  return (
    <BazaarVoiceComponent
      componentName="rating_summary"
      productId={product.id}
    />
  );
};

RatingAndReviews.defaultProps = {
  product: {},
  cart: {},
  appCtx: {},
  isUserLoggedIn: false
};

RatingAndReviews.propTypes = {
  product: PropTypes.object,
  cart: PropTypes.object,
  appCtx: PropTypes.object,
  isUserLoggedIn: PropTypes.bool
};

const RARWithContext = withApplicationContext(
  withCart(withUser(withProduct(RatingAndReviews)))
);
export { RARWithContext as RatingAndReviews };
