import React from 'react';
import PropTypes from 'prop-types';
import GenericCalculatorContainer from '../../../GenericCalculatorContainer/GenericCalculatorContainer';
import AddToCartButton from '../../../../components/AddToCartButton/Desktop/AddToCartButtonDesktop';
import Warning from '../../../../components/Warning/Warning';
import noop from '../../../../utils/noop';
import { ProductSpecificationsDesktopStyle } from './ProductSpecificationsDesktop.style';
import useInput from '../../useInput';

const ProductQuantitySelectionContainer = ({
  maxCartQuantity,
  addToCartButtonVisible,
  errorAddingToCart,
  isAddToCartInProgress,
  isRemoveFromCartInProgress,
  handleRemoveFromCart,
  labels,
  handleAddToCart,
  isVendorLogin,
  sizes,
  selectedSize,
  isRebrandingEnabled,
  setShowLoginForm,
  showLoginForm,
  setErrorAddingToCart,
  m2AttributeValue,
  layout
}) => {
  const {
    showQuantityError,
    inputVal,
    handleInputChange,
    setQuantityError,
    setInputVal
  } = useInput(maxCartQuantity);

  return (
    <>
      {layout === 'hardline' ? (
        <GenericCalculatorContainer handleInputChange={handleInputChange} />
      ) : null}
      {addToCartButtonVisible && (
        <div className="addToCart-container fa--add-to-cart__desktop">
          <AddToCartButton
            addToCartButtonVisible={addToCartButtonVisible}
            isAddToCartInProgress={isAddToCartInProgress}
            labels={labels}
            maxCartQuantity={maxCartQuantity}
            handleAddToCart={handleAddToCart}
            handleRemoveFromCart={handleRemoveFromCart}
            isRemoveFromCartInProgress={isRemoveFromCartInProgress}
            isVendorLogin={isVendorLogin}
            sizes={sizes}
            selectedSize={selectedSize}
            isRebrandingEnabled={isRebrandingEnabled}
            setShowLoginForm={setShowLoginForm}
            showLoginForm={showLoginForm}
            setErrorAddingToCart={setErrorAddingToCart}
            m2AttributeValue={m2AttributeValue}
            showQuantityError={showQuantityError}
            setQuantityError={setQuantityError}
            inputVal={inputVal}
            setInputVal={setInputVal}
            handleInputChange={handleInputChange}
          />
          {errorAddingToCart && (
            <Warning
              label={errorAddingToCart}
              iconSize="copy10"
              labelSize="copy3"
              forPDP
            />
          )}
        </div>
      )}
      <style jsx>{ProductSpecificationsDesktopStyle}</style>
    </>
  );
};

ProductQuantitySelectionContainer.defaultProps = {
  addToCartButtonVisible: true,
  errorAddingToCart: false,
  isAddToCartInProgress: false,
  handleAddToCart: noop,
  handleRemoveFromCart: noop,
  isRemoveFromCartInProgress: false,
  sizes: [],
  selectedSize: '',
  showLoginForm: false,
  m2AttributeValue: -1,
  layout: 'hardline'
};

ProductQuantitySelectionContainer.propTypes = {
  maxCartQuantity: PropTypes.number.isRequired,
  addToCartButtonVisible: PropTypes.bool,
  errorAddingToCart: PropTypes.bool,
  isAddToCartInProgress: PropTypes.bool,
  handleRemoveFromCart: PropTypes.func,
  isRemoveFromCartInProgress: PropTypes.bool,
  labels: PropTypes.object.isRequired,
  handleAddToCart: PropTypes.func,
  isVendorLogin: PropTypes.bool.isRequired,
  sizes: PropTypes.array,
  selectedSize: PropTypes.string,
  isRebrandingEnabled: PropTypes.bool.isRequired, // TODO
  setShowLoginForm: PropTypes.func.isRequired,
  showLoginForm: PropTypes.bool,
  setErrorAddingToCart: PropTypes.func.isRequired,
  m2AttributeValue: PropTypes.number,
  layout: PropTypes.string
};

export default ProductQuantitySelectionContainer;
