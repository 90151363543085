/* eslint-disable no-param-reassign */
import constants from '../config/constants';
import _ from './LodashImports';

const PRICE_TYPES = {
  cmrPrice: 'cmrPrice',
  internetPrice: 'internetPrice',
  normalPrice: 'normalPrice',
  eventPrice: 'eventPrice'
};
const LABELS_MAP = {
  'Sub-total productos': '',
  'Monto final a pagar:': '',
  'Con CMR:': '',
  CMR: ''
};

const CURRENCY_MAPPER = {
  CLP: '$',
  ARS: '$',
  COP: '$',
  PEN: 'S/ '
};

const ICONS_MAP = {
  CMR: 'cmr-icon',
  'Con CMR:': 'cmr-icon',
  cmr: 'cmr-icon'
};

const commercePriceTypeMap = {
  cmr: PRICE_TYPES.cmrPrice,
  internet: PRICE_TYPES.internetPrice,
  normal: PRICE_TYPES.normalPrice,
  event: PRICE_TYPES.normalPrice
};

const atgPriceTypeMap = {
  1: PRICE_TYPES.cmrPrice,
  2: PRICE_TYPES.normalPrice,
  3: PRICE_TYPES.internetPrice,
  4: PRICE_TYPES.normalPrice
};

const countDecimalPlaces = (number) => {
  const numStr = String(number);

  if (numStr.includes('.')) {
    return numStr.split('.')[1].length;
  }
  return 0;
};

const getPriceType = (type) => {
  if (typeof type === 'string') {
    return type;
  }
  return atgPriceTypeMap[type];
};

const getPriceToNumber = (price, tenant) => {
  const decimalSeparator = constants.DECIMAL_SEPARATOR[tenant];
  const dotSeparator = constants.DOT_SEPARATOR[tenant];

  const decimalRegex = new RegExp(`\\${decimalSeparator}`, 'ig');
  const dotRegex = new RegExp(`\\${dotSeparator}`, 'ig');

  const formatedPrice = price
    .replace(decimalRegex, '[DOT]')
    .replace(dotRegex, '')
    .replace('[DOT]', '.');

  return Number(formatedPrice);
};

const willBeCrossedOut = (isNormalPriceCrossedOut, prices) => {
  const isPriceCrossedOut =
    prices.length === 3 || (prices.length === 2 && !prices[0].icons);

  if (isNormalPriceCrossedOut && isPriceCrossedOut) {
    return true;
  }

  return false;
};

const getLabel = (label) =>
  typeof LABELS_MAP[label] === 'undefined' ? label : LABELS_MAP[label];

const mapATGPricesToCatalystPrices = (prices = [], tenant = 'cl', toggles) => {
  prices.sort((a, b) => {
    return (
      getPriceToNumber(a.purchasedPrice || a.originalPrice, tenant) -
      getPriceToNumber(b.purchasedPrice || b.originalPrice, tenant)
    );
  });

  const isNormalPriceCrossedOut = _.get(
    toggles,
    'isNormalPriceCrossedOut',
    false
  );

  const formattedPrice = prices.map(
    ({
      label,
      purchasedPrice,
      originalPrice,
      symbol,
      type,
      opportunidadUnica
    }) => {
      return {
        label: isNormalPriceCrossedOut ? '' : getLabel(label),
        price: [purchasedPrice || originalPrice],
        symbol,
        icons: opportunidadUnica ? 'cmr-icon' : ICONS_MAP[label] || '',
        type: getPriceType(type),
        crossed: false
      };
    }
  );

  if (formattedPrice.length) {
    formattedPrice[formattedPrice.length - 1].crossed = willBeCrossedOut(
      isNormalPriceCrossedOut,
      formattedPrice
    );
  }

  return formattedPrice;
};

const formatters = {
  NOT_DECIMAL: (val) => {
    return val.toString().split('.')[0];
  },
  DOT_SEPERATOR: (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  },
  COMMA_SEPERATOR: (val) => {
    const valueWithDecimal = val.toFixed(2).split('.00')[0];
    return valueWithDecimal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};

const config = {
  cl: {
    formatters: ['NOT_DECIMAL', 'DOT_SEPERATOR']
  },
  co: {
    formatters: ['NOT_DECIMAL', 'DOT_SEPERATOR']
  },
  ar: {
    formatters: ['NOT_DECIMAL', 'DOT_SEPERATOR']
  },
  pe: {
    formatters: ['COMMA_SEPERATOR']
  }
};

const formatter = (rules, price) => {
  return rules.reduce((acc, crr, i, array) => {
    crr = formatters[array[i]](acc);
    return crr;
  }, price);
};

const formatNumberToPrice = (price, tenant = 'cl') => {
  return formatter(config[tenant].formatters, price);
};

const getCommercePriceLabel = (textDictionary, type) => {
  const { OFFER_PRICE_LABEL, NORMAL_PRICE_LABEL } = textDictionary;
  if (type === 'internet' || type === 'event') {
    return OFFER_PRICE_LABEL;
  }
  if (type === 'normal') {
    return NORMAL_PRICE_LABEL;
  }
  return '';
};

const shouldNormalPriceBeCrossed = (
  hasCmrPrice,
  hasNormalPrice,
  isNormalPriceCrossedOut,
  mappedPrice
) => {
  if (isNormalPriceCrossedOut && hasNormalPrice && mappedPrice.length > 1) {
    if (hasCmrPrice && mappedPrice.length === 2) {
      return false;
    }
    return true;
  }
  return false;
};

const formatCommercePrize = (prices, tenant, textDictionary, toggles) => {
  let hasNormalPrice = false;
  let hasCmrPrice = false;

  const isNormalPriceCrossedOut = _.get(
    toggles,
    'isNormalPriceCrossedOut',
    false
  );

  const mappedPrice = prices.map((item) => {
    const { price, type } = item;

    if (type === 'cmr') {
      hasCmrPrice = true;
    }

    if (type === 'normal') {
      hasNormalPrice = true;
    }

    const { centAmount, fraction, currency } = price;
    const convertedPrice = centAmount / fraction;
    return {
      label: getCommercePriceLabel(textDictionary, type),
      price: [formatNumberToPrice(convertedPrice, tenant)],
      symbol: CURRENCY_MAPPER[currency] || '',
      icons: ICONS_MAP[type] || '',
      type: commercePriceTypeMap[type] || '',
      crossed: false
    };
  });

  if (
    shouldNormalPriceBeCrossed(
      hasCmrPrice,
      hasNormalPrice,
      isNormalPriceCrossedOut,
      mappedPrice
    )
  ) {
    mappedPrice[mappedPrice.length - 1].crossed = true;
  }

  return mappedPrice;
};

const formatPrice = (price, symbol) => {
  return `${symbol} ${price}`;
};

const ALLOWED_CONFIGS_FOR_PRICE_BREAK = [
  `${constants.DEVICE_DESKTOP.toUpperCase()}_${constants.LAYOUT_4_GRID}`,
  `${constants.DEVICE_DESKTOP.toUpperCase()}_${constants.LAYOUT_3_GRID}`,
  `${constants.DEVICE_MOBILE.toUpperCase()}_${constants.LAYOUT_2_GRID}`
];
const PRICE_RANGE_EXTRA_CHARACTERS = 7;
const PLP_PRICE_RANGE_BREAKPOINTS = {
  DESKTOP_4_GRID: {
    LOWER: 18,
    UPPER: 23
  },
  DESKTOP_3_GRID: {
    LOWER: 21,
    UPPER: 27
  },
  MOBILE_2_GRID: {
    LOWER: 13,
    UPPER: 18
  }
};

const formatPriceRange = (
  view = 'MOBILE_2_GRID',
  priceRange = [''],
  productPricesCount = 0,
  isCmr = false,
  symbol = '$'
) => {
  const totalCharacters =
    priceRange.reduce((acc, v) => acc + v.length, 0) +
    PRICE_RANGE_EXTRA_CHARACTERS;
  const limit =
    productPricesCount === 3 || (productPricesCount === 2 && isCmr)
      ? 'LOWER'
      : 'UPPER';

  const breakpoint = _.get(
    PLP_PRICE_RANGE_BREAKPOINTS,
    `${view}.${limit}`,
    PLP_PRICE_RANGE_BREAKPOINTS.MOBILE_2_GRID.LOWER
  );

  if (totalCharacters > breakpoint) {
    return priceRange.map((p, i) => {
      const formatedPrice = formatPrice(p, symbol);
      return i === 0 ? `${formatedPrice} -` : formatedPrice;
    });
  }
  return [priceRange.map((p) => formatPrice(p, symbol)).join(' - ')];
};

const ALLOWED_CONFIGS_FOR_UNIT_PRICES = [
  'PDP_MAIN',
  'PDP_MAIN_MOBILE',
  'DESKTOP_4_GRID',
  'DESKTOP_3_GRID',
  'DESKTOP_2_GRID',
  'DESKTOP_LIST',
  'MOBILE_2_GRID',
  'MOBILE_1_GRID',
  'MOBILE_LIST'
];

export {
  mapATGPricesToCatalystPrices,
  getPriceToNumber,
  formatNumberToPrice,
  formatCommercePrize,
  countDecimalPlaces,
  CURRENCY_MAPPER,
  formatPriceRange,
  ALLOWED_CONFIGS_FOR_PRICE_BREAK,
  ALLOWED_CONFIGS_FOR_UNIT_PRICES,
  formatters,
  PRICE_TYPES
};
