import css from 'styled-jsx/css';
import colors from '../../config/styles/colors';
import typography from '../../config/styles/typography';

const ExpandableSwatchStyle = css`
  .swatch {
    position: relative;
    overflow: hidden;

    &-collapsed {
      overflow: hidden;
      @mixin desktop {
        border-radius: 12px;
      }

      &::after {
        position: absolute;
        bottom: 0;
        height: 140px;
        width: 100%;
        content: '';
        box-shadow: inset 0 -140px 35px 0 ${colors.white.shade1};
        @mixin desktop {
          border-radius: 12px;
        }
      }
    }
    &-expanded {
      padding-bottom: 30px;
      background-color: white;
      @mixin desktop {
        border-radius: 12px;
      }
    }

    .swatchButton {
      text-transform: uppercase;
      position: absolute;
      font-size: ${typography.sm};
      font-weight: 700;
      letter-spacing: 1px;
      line-height: 15px;
      border: 1px solid ${colors.gray.shade39};
      border-radius: 5px;
      padding: 11px 0;
      width: 220px;
      height: 40px;
      color: ${colors.gray.shade15};
      left: 0;
      right: 0;
      z-index: 2;
      margin: auto;
      background-color: ${colors.white.shade1};
      display: flex;
      justify-content: center;
      align-items: center;
      @mixin tabletToDesktop {
        padding: 9px 0;
        width: 189px;
      }

      &-expandButton {
        position: static;
      }
      &-collapseButton {
        bottom: 33px;
      }

      span.icon-right {
        padding-left: 12px;
        font-size: ${typography.xxs1};
        font-weight: bold;
        display: flex;
        height: 8px;
        width: 8px;
        justify-content: center;
        align-items: center;
      }
    }
    .mkp-swatchButton {
      position: absolute;
      border: 2px solid ${colors.blue.shade3};
      box-sizing: border-box;
      border-radius: 20px;
      width: 190px;
      height: 40px;
      left: 0;
      right: 0;
      z-index: 2;
      margin: auto;
      background-color: ${colors.white.shade1};
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.5px;
      color: ${colors.blue.shade3};
      @mixin tabletToDesktop {
        padding: 9px 0;
        width: 189px;
      }

      &-expandButton {
        position: static;
      }
      &-collapseButton {
        bottom: 33px;
      }
      &.so_com {
        border-radius: 4px;
      }
      span::first-letter {
        text-transform: uppercase;
      }
      span.icon-right {
        padding-left: 12px;
        font-size: ${typography.xxs1};
        font-weight: bold;
        display: flex;
        height: 8px;
        width: 8px;
        justify-content: center;
        align-items: center;
      }
      .csicon-arrow_down_small {
        display: none;
      }
      .csicon-arrow_up_small {
        display: none;
      }
    }
  }
`;

export { ExpandableSwatchStyle };
