import React from 'react';
import PropTypes from 'prop-types';
import ProductSpecificationStyle from './PDPBasicInfoDesktop.style';
import {
  ProductName,
  BrandName,
  VariantId,
  ProductShareIcon
} from '../../../../../components/PDP';

import Badges from '../../../../../components/Badges/Badges';
import { getVariant } from '../../../../../utils/variant';
import { withRatings } from '../../../../../utils/RatingsContext';
import { withApplicationContext } from '../../../../../utils/ApplicationContext';
import _ from '../../../../../utils/LodashImports';
import { RatingAndReviews } from '../../../../../external_dependencies/ratingAndReviews/RatingAndReviews';
import { SKUIdSeller } from '../../../../common/PDPBasicInfoShared';

const PDPBasicInfoDesktop = ({ product, ratings, labels, appCtx }) => {
  const {
    badges = [],
    meatStickers = [],
    offerings = [],
    promotions
  } = getVariant(product.variants, product.currentVariant);
  const reviewsClass = badges.length || ratings ? '' : 'hidden'; // Don't change this logic, in order to update reviews we need the section to render so data loads
  const rcClass = ratings ? '' : 'hidden';
  const { isRebrandingEnabled, regionCode, store } = appCtx;
  const isSoCom = store === 'so_com';
  const isMeatStickerApplicableInPDP = _.get(
    appCtx,
    'siteConfig.toggles.isMeatStickerApplicableInPDP',
    false
  );
  const allowed3PCampaignNamesToRender = _.get(
    appCtx,
    'siteConfig.textDictionary.ALLOWED_3P_CAMPAIGN_NAMES_TO_RENDER',
    'ENVIO GRATIS 3P, ENVIO_GRATIS_3P'
  );

  const freeShippingPromotionFor3p = promotions?.find((promotion) =>
    allowed3PCampaignNamesToRender
      .split(',')
      .map((name) => name.trim())
      .includes(promotion.campaignName)
  );

  const hasStickers =
    (isMeatStickerApplicableInPDP && meatStickers.length > 0) ||
    freeShippingPromotionFor3p;
  const { isOutOfStock } = product;

  if (isOutOfStock) {
    return (
      <div
        className={`basic-details-Desktop ${isRebrandingEnabled &&
          'rebranded'} out-of-stock`}
      >
        <div className="corner-details">
          <BrandName brandName={product.brandName} />
        </div>
        <div className="corner-details">
          <ProductName productName={product.name} />
        </div>
        <div className={`reviews ${reviewsClass}`}>
          <div className={`reviews-column ${rcClass}`}>
            {/* Space for Review need to present, to prevent bouncing */}
            <RatingAndReviews />
          </div>
        </div>

        <style jsx>{ProductSpecificationStyle}</style>
      </div>
    );
  }

  return (
    <div
      className={`basic-details-Desktop ${isRebrandingEnabled && 'rebranded'}`}
    >
      <div className="corner-details">
        <BrandName brandName={product.brandName} />
        <div className="variant-seller-ids">
          <VariantId
            variantId={product.currentVariant}
            labels={labels}
            isSoCom={isSoCom}
          />
          {SKUIdSeller({
            appCtx,
            offerings,
            labels,
            regionCode,
            product,
            customStyle: ProductSpecificationStyle
          })}
        </div>
      </div>
      <div className="corner-details">
        <ProductName productName={product.name} />
        <ProductShareIcon isRebrandingEnabled={isRebrandingEnabled} />
      </div>
      <div className={`reviews ${reviewsClass}`}>
        <div className={`reviews-column ${rcClass}`}>
          {/* Space for Review need to present, to prevent bouncing */}
          <RatingAndReviews />
        </div>
        <Badges
          badgeItems={isMeatStickerApplicableInPDP ? meatStickers : badges}
          layout="PDP"
          isSticker={hasStickers}
          promotionBadge={freeShippingPromotionFor3p?.badge}
        />
      </div>

      <style jsx>{ProductSpecificationStyle}</style>
    </div>
  );
};

PDPBasicInfoDesktop.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  ratings: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired
};

export default withRatings(withApplicationContext(PDPBasicInfoDesktop));
