/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { isTottusStandalone } from '@digital-retail/store-manager';

import CookieStorage from './CookieStorage';
import _ from './LodashImports';
import constants from '../config/constants';

const useReco = ({
  rutHash,
  abTestingCookieName,
  getRecommendedProducts,
  pdpAB,
  slots,
  index,
  sponsoredProducts,
  toggles,
  politicalId,
  priceGroupId,
  store
}) => {
  const [recoData, setRecoData] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchRecos = async () => {
    const { getCookie, unescapeCookies } = CookieStorage;
    const isToCom = isTottusStandalone(store);
    const widgetCookie = getCookie(`${abTestingCookieName}`);
    const contextId = toggles.shouldSendContextIdToReco
      ? getCookie('contextID')
      : null;
    const userIdFromCookie =
      isToCom && toggles.shouldSendUserIdToReco ? getCookie('USER_ID') : null;

    const widgetUUID = _.get(widgetCookie.split('*'), `${index}`, '');
    const dynDataCookie = unescapeCookies(getCookie('sid'));
    const isUserLoggedIn = !!dynDataCookie;
    if (
      (toggles.isPDPWidgetGlobalEnabled || widgetCookie) &&
      !(isUserLoggedIn && !rutHash)
    ) {
      let details;
      const data = await getRecommendedProducts(
        slots,
        widgetUUID,
        contextId,
        userIdFromCookie
      );
      if (Array.isArray(data) && data.length) {
        const carouselsForDigitalData = [];
        data.forEach((widget) => {
          switch (widget.type) {
            case 'bundles':
              if (toggles.isInhouseBundleWidgetActive) {
                carouselsForDigitalData.push({
                  name: widget.name,
                  position: index + 1
                });
              }
              break;
            case 'comparative':
              if (
                toggles.isComparativeTableEnabled &&
                widget.data.length >= 3
              ) {
                carouselsForDigitalData.push({
                  name: widget.name,
                  position: index + 1
                });
              }
              break;
            case 'carousel': {
              carouselsForDigitalData.push({
                name: widget.name,
                position: index + 1
              });
              break;
            }
            default:
              break;
          }
        });
        if (index === 0 && sponsoredProducts.length) {
          carouselsForDigitalData.push({
            name: constants.SPONSORED_PRODUCTS_CAROUSEL_HEADING,
            position: carouselsForDigitalData.length + 1
          });
        }
        details = {
          slot: index + 1,
          carousels: carouselsForDigitalData
        };
      } else {
        details = 'none';
      }
      const eventToDigitalData = new CustomEvent('DDPDPCarouselsLoad', {
        bubbles: true,
        detail: details
      });
      window.dispatchEvent(eventToDigitalData);
      setRecoData(data);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchRecos()
      .then(() => setLoading(false))
      .catch(() => {
        console.error('Error in useReco Hook');
        setLoading(false);
      });
  }, [rutHash, pdpAB, politicalId, priceGroupId]);

  return { recoData, loading };
};

export default useReco;
