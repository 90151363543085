/* eslint-disable camelcase */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ReturnPolicyModal.style';
import _ from '../../../utils/LodashImports';
import { getContent } from './ReturnPolicy.helper';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import constants from '../../../config/constants';

const ReturnPolicyModal = ({
  actions,
  textDictionary,
  url,
  deviceType,
  tenant,
  appCtx
}) => {
  const modalFooter = _.get(
    textDictionary,
    'RETURN_POLICY_MODAL_FOOTER',
    'Para más información revisa nuestro'
  );
  const modalFooterUnderline = _.get(
    textDictionary,
    'RETURN_POLICY_MODAL_FOOTER_UNDERLINE_TEXT',
    'Centro de ayuda'
  );
  const isTocom = appCtx.store === constants.STORES.to_com;
  const contents = getContent(tenant, textDictionary, appCtx.store);

  React.useEffect(() => {
    if (deviceType === 'desktop') {
      actions.setCustomHeader(
        <React.Fragment>
          <div className="title-wrapper main">
            <p
              dangerouslySetInnerHTML={{
                __html: contents[0].title
              }}
            />
          </div>
          <style jsx>{styles}</style>
        </React.Fragment>
      );
    }
  }, []);

  return (
    <div
      className={classNames('modal-container', {
        to_com: isTocom
      })}
    >
      <div className="contents-container">
        {contents.map((c, i) => (
          <React.Fragment key={c.title}>
            {(deviceType === 'desktop' && i === 0) || !c.title ? null : (
              <div
                className={classNames('title-wrapper', {
                  main: i === 0,
                  old: tenant === 'cl'
                })}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: c.title
                  }}
                />
              </div>
            )}
            <div className="rp-modal-body">
              {!!c.para1 && (
                <p
                  className={classNames('paragraph', {
                    'p1-new': tenant !== 'cl',
                    'p-1': tenant === 'cl',
                    'margin-top-11': i === 0,
                    old: tenant === 'pe'
                  })}
                  dangerouslySetInnerHTML={{
                    __html: c.para1
                  }}
                />
              )}
              {tenant === 'cl' && !!c.para2 && <div className="divider-line" />}
              {!!c.para2 && (
                <div
                  className={classNames('paragraph-wrapper', {
                    center: tenant === 'cl'
                  })}
                >
                  {c.para2Icon && <div className="no-refund-icon" />}
                  <p
                    className={classNames('paragraph', {
                      'p2-new': tenant !== 'cl',
                      'p-2': tenant === 'cl'
                    })}
                    dangerouslySetInnerHTML={{
                      __html: c.para2
                    }}
                  />
                </div>
              )}
              {!!c.list && (
                <ul
                  className={classNames({
                    'new-ul-padding': tenant !== 'cl',
                    'padding-top-0': !c.para1 && !c.para2
                  })}
                >
                  {!!c.list &&
                    c.list.split('*').map((item, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <li
                        key={`${c.title}_li_${index}`}
                        dangerouslySetInnerHTML={{
                          __html: item
                        }}
                      />
                    ))}
                </ul>
              )}
              {!!c.para3 && (
                <div className="paragraph-wrapper">
                  {!!c.para3Icon && <div className="no-refund-icon" />}
                  <p
                    className={classNames('paragraph', {
                      'p2-new': tenant !== 'cl'
                    })}
                    dangerouslySetInnerHTML={{
                      __html: c.para3
                    }}
                  />
                </div>
              )}
              {tenant !== 'cl' && i !== contents.length - 1 && (
                <div className="divider-line" />
              )}
            </div>
          </React.Fragment>
        ))}
      </div>
      {modalFooter && modalFooter !== '' && (
        <div className="rp-modal-footer">
          <div className="divider-line" />
          <div className="footer-wrapper">
            <i className="csicon-alert alertIcon" />
            <a
              id="testId-help-center-btn"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p>
                {modalFooter} <b>{modalFooterUnderline}</b>.
              </p>
            </a>
          </div>
        </div>
      )}
      <style jsx>{styles}</style>
    </div>
  );
};

ReturnPolicyModal.propTypes = {
  actions: PropTypes.object.isRequired,
  textDictionary: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
  tenant: PropTypes.string.isRequired,
  appCtx: PropTypes.object.isRequired
};

export default withApplicationContext(ReturnPolicyModal);
