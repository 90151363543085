import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';
import _ from '../../../utils/LodashImports';
import constants from '../../../config/constants';

export const buttonStyle = (theme, store) => {
  const themeColor = _.get(theme, 'color', null) || colors.secondary_color;
  const secondaryColor =
    _.get(theme, 'secondary_color', null) || colors.secondary_color;

  const { STORES } = constants;

  const buttonColor =
    store === STORES.to_com ? secondaryColor : colors.white.shade1;

  return css`
    .button {
      font-family: inherit;
      font-weight: 400;
      font-size: ${typography.sm};
      border-radius: 3px;
      text-align: center;
      width: 100%;
      cursor: pointer;
      text-transform: uppercase;
      color: ${colors.gray.shade19};
      &.btn-hidden {
        display: none;
      }
      &.button-primary {
        font-weight: 700;
        background-color: ${colors.green.shade1};
        color: ${colors.white.shade1};
        height: 36px;
        &-medium {
          height: 41px;
        }
        &-large {
          height: 44px;
        }
        &-medium-large {
          height: 48px;
          font-size: ${typography.base};
        }
        &-xtra-large {
          height: 55px;
          font-size: ${typography.base};
          letter-spacing: 1px;
        }
        &.so_com {
          border-radius: 4px;
        }
      }
      &.button-secondary {
        height: 40px;
        width: 100%;
        font-size: ${typography.sm1};
        background-color: ${colors.gray.shade15};
        color: ${colors.white.shade1};

        &-medium {
          width: 100%;
          font-size: ${typography.xs2};
        }

        &-medium-large {
          width: 100%;
          height: 45px;
          border-radius: 3px;
          font-weight: bold;
          &.so_com {
            border-radius: 4px;
          }
        }

        &-large {
          width: 100%;
          height: 45px;
          font-size: ${typography.base};
        }
        &.so_com {
          border-radius: 4px;
        }
      }
      &.button-tertiary {
        height: 39px;
        font-size: ${typography.sm1};
        border: 1px solid ${colors.gray.shade16};
        background-color: ${colors.gray.shade3};
        color: ${colors.gray.shade15};
      }
      &.button-sort-button {
        height: 31px;
        width: 30px;
        font-size: ${typography.sm1};
        border: 1px solid ${colors.gray.shade5};
        color: ${colors.gray.shade15};
      }
      &.button-quatinery {
        height: 54px;
        font-size: ${typography.sm1};
        background-color: ${colors.gray.shade15};
        color: ${colors.gray.shade3};
      }
      &.button-link {
        height: 40px;
        color: ${colors.gray.shade19};
        text-decoration: underline;
        letter-spacing: 1px;
        text-transform: none;
      }
      &.button-secondary-link {
        border-radius: 0;
        text-transform: none;
        color: ${colors.black.shade9};
        font-size: ${typography.sm1};
        letter-spacing: 0.19px;
        line-height: 16px;
        border-bottom: 0.5px solid ${colors.black.shade9};
        &.so_com {
          border-radius: 4px;
        }
      }
      &.button-secondary-link-mkp {
        border-radius: 0;
        text-transform: none;
        color: ${colors.black.shade10};
        font-size: ${typography.base};
        letter-spacing: 0.19px;
        line-height: 16px;
        border-bottom: 0.5px solid ${colors.black.shade9};
        &.so_com {
          border-radius: 4px;
        }
      }
      &.button-tertiary-link {
        border-radius: 0;
        text-transform: none;
        color: ${colors.black.shade9};
        font-size: ${typography.base};
        letter-spacing: 0.19px;
        line-height: 16px;
        border-bottom: 0.5px solid ${colors.black.shade9};
        &.so_com {
          border-radius: 4px;
        }
      }
      &.button-outline {
        border: 1px solid ${colors.gray.shade4};
        border-radius: 3px;
        color: ${colors.gray.shade4};
        font-size: ${typography.sm};
        font-weight: bold;
        letter-spacing: 0.14px;
        line-height: 17px;
        height: 55px;

        &-xtra-large {
          height: 55px;
          font-size: ${typography.base};
          letter-spacing: 1px;
        }
        &.so_com {
          border-radius: 4px;
        }
      }
      &.button-mkp-primary {
        font-weight: 700;
        background-color: ${themeColor};
        color: ${buttonColor};
        height: 36px;
        text-transform: none;
        border-radius: 30px;
        line-height: 1.2;
        &-medium {
          height: 40px;
          font-size: ${typography.xla};
          font-weight: 700;
          background-color: ${colors.secondary_color};
          color: ${buttonColor};
          text-transform: none;
          border-radius: 30px;
          line-height: 1.2;
          &[disabled] {
            background-color: ${colors.gray.shade46};
            color: ${colors.gray.shade47};
            font-weight: normal;
          }
          &.so_com {
            border-radius: 4px;
          }
          &.to_com {
            color: ${colors.gray.shade66};
          }
        }
        &-large {
          height: 44px;
          font-size: ${typography.xla};
        }
        &-medium-large {
          height: 48px;
          letter-spacing: 0.5px;
          font-size: ${typography.xla};
        }
        &-xtra-large {
          height: 56px;
          font-size: ${typography.xla};
          font-weight: bold;
          line-height: 23px;
          letter-spacing: 0.5px;
          color: ${buttonColor};
          border-radius: 30px;
          background-color: ${themeColor};
          text-transform: none;
          &-outlined {
            background: #fff;
            color: ${themeColor};
            border: 2px solid ${themeColor};
          }
        }
        &-mkp-large {
          height: 45px;
          font-weight: bold;
          font-size: 19px;
          line-height: 23px;
          text-align: center;
          color: ${colors.white.shade1};
        }
        &[disabled] {
          background-color: ${colors.gray.shade46};
          color: ${colors.gray.shade47};
        }
        &.so_com {
          border-radius: 4px;
        }
        &.to_com {
          color: ${colors.gray.shade66};
        }
      }

      &.button-mkp-secondary {
        background-color: ${secondaryColor};
        color: ${colors.white.shade1};
        height: 40px;
        text-transform: none;
        border-radius: 30px;
        line-height: 1.2;
        font-size: ${typography.xla};
        display: flex;
        align-items: center;
        justify-content: center;
        &-medium {
          padding: 8px 24px 9px;
          font-weight: 400;
          line-height: 23px;
          border-radius: 20px;
          letter-spacing: 0.5px;
          @mixin tabletToDesktop {
            letter-spacing: 1px;
          }
          &[disabled] {
            letter-spacing: 0.5px;
          }
          &-extend {
            height: 41px;
            padding: 9px 24px;
          }
          &.so_com {
            border-radius: 4px;
          }
        }
        &-small {
          padding: 8px 24px 9px;
          font-weight: 400;
          font-size: ${typography.lg1};
          letter-spacing: 0.5px;
          border-radius: 20px;
          line-height: 19px;
          height: 36px;
          @mixin tabletToDesktop {
            height: 40px;
            letter-spacing: 1px;
            font-size: ${typography.xla};
            line-height: 23px;
          }
        }
        &-large {
          height: 44px;
        }
        &-medium-large {
          height: 48px;
        }
        &-xtra-large {
          height: 56px;
          letter-spacing: 1px;
        }
        &[disabled] {
          background-color: ${colors.gray.shade46};
          color: ${colors.gray.shade47};
        }
        &[disabled].to_com.create-new-list-button {
          background-color: ${colors.gray.shade12};
          color: ${colors.gray.shade67};
        }
        &-strech-full {
          width: 100%;
        }
        &-strech-auto {
          width: auto;
        }
        &-letter-small {
          letter-spacing: 0.5px;
        }
        &.generic-outlined {
          border-radius: 80px;
          border: 1.5px solid ${secondaryColor};
          background: #fff;
          color: ${secondaryColor};
        }
        &.so_com {
          border-radius: 4px;
        }
        &.to_com {
          color: ${colors.gray.shade66};
        }
        &.to_com.create-new-list-button {
          background-color: ${colors.green.shade28};
          color: ${colors.gray.shade66};
        }
      }
      &.button-mkp-quatinery {
        background-color: ${colors.gray.shade45};
        color: ${colors.white.shade1};
        padding: 5px;
        text-transform: none;
        border-radius: 28px;
        line-height: 22px;
        font-size: ${typography.lg1};
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        &[disabled] {
          background-color: ${colors.gray.shade46};
          color: ${colors.gray.shade47};
        }
        &-strech-full {
          width: 100%;
        }
        &-strech-auto {
          width: auto;
        }
        &-letter-small {
          letter-spacing: 0.5px;
        }
        &.generic-outlined {
          border-radius: 80px;
          border: 1.5px solid ${secondaryColor};
          background: #fff;
          color: ${secondaryColor};
        }
        &.so_com {
          border-radius: 4px;
        }
        &.to_com {
          color: ${colors.gray.shade66};
        }
      }

      &.button-mkp-tertiary {
        background: ${colors.blue.shade3};
        border-radius: 20px;
        font-weight: normal;
        font-size: 19px;
        line-height: 23px;
        text-align: center;
        letter-spacing: 0.5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 280px;
        height: 41px;
        color: ${colors.white.shade1};
        text-transform: none;
        @mixin mobileToTablet {
          width: 334px;
        }
        &-medium {
          height: 40px;
          font-size: ${typography.xla};
          font-weight: 700;
          background-color: ${colors.secondary_color};
          color: ${colors.white.shade1};
          text-transform: none;
          border-radius: 30px;
          line-height: 1.2;
          &[disabled] {
            background-color: ${colors.gray.shade46};
            color: ${colors.gray.shade47};
            font-weight: normal;
          }
          &.so_com {
            border-radius: 4px;
          }
          &.to_com {
            color: ${colors.gray.shade66};
          }
        }
      }
      &.button-bold {
        font-weight: 700;
      }

      &[disabled] {
        background-color: ${colors.gray.shade5};
        cursor: initial;
      }
      &.so_com {
        border-radius: 4px;
      }
      &.to_com {
        color: ${colors.gray.shade66};
      }
    }
  `;
};
