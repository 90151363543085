module.exports = {
  ENV_HOST: {
    CL: 'https://staging.falabella.com',
    PE: 'https://staging.falabella.com.pe',
    CO: 'https://staging.falabella.com.co',
    AR: 'https://staging.falabella.com.ar'
  },
  FULL_HOST: {
    CL: 'https://staging.falabella.com/falabella-cl',
    CL_SODIMAC: 'https://staging-sodimac.falabella.com/sodimac-cl',
    CL_SO_COM: 'https://staging.sodimac.cl/sodimac-cl',
    PE_SO_COM: 'https://staging.sodimac.com.pe/sodimac-pe',
    CL_TO_COM: 'https://staging.tottus.cl/tottus-cl',
    PE_TO_COM: 'https://staging.tottus.com.pe/tottus-pe',
    CL_LINIO: 'https://staging-linio.falabella.com/linio-cl',
    CL_TOTTUS: 'https://staging-tottus.falabella.com/tottus-cl',
    CL_TIENDA: 'https://staging-tienda.falabella.com/falabella-cl',
    PE: 'https://staging.falabella.com.pe/falabella-pe',
    PE_LINIO: 'https://staging-linio.falabella.com.pe/linio-pe',
    PE_TOTTUS: 'https://staging-tottus.falabella.com.pe/tottus-pe',
    PE_SODIMAC: 'https://staging-sodimac.falabella.com.pe/sodimac-pe',
    PE_TIENDA: 'https://staging-tienda.falabella.com.pe/falabella-pe',
    CO: 'https://staging.falabella.com.co/falabella-co',
    CO_LINIO: 'https://staging-linio.falabella.com.co/linio-co',
    CO_TOTTUS: 'https://staging-tottus.falabella.com.co/tottus-co',
    CO_SODIMAC: 'https://staging-sodimac.falabella.com.co/sodimac-co',
    CO_TIENDA: 'https://staging-tienda.falabella.com.co/falabella-co',
    CO_HOMECENTER: 'https://staging-homecenter.falabella.com.co/homecenter-co',
    AR: 'https://staging.falabella.com.ar/falabella-ar'
  },
  DY_DYNAMIC_API: '//cdn.dynamicyield.com/api/{DY_ID}/api_dynamic.js',
  DY_STATIC_API: '//cdn.dynamicyield.com/api/{DY_ID}/api_static.js'
};
