import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isSodimacStandalone } from '@digital-retail/store-manager';
import BodyCopy from '../../ui/BodyCopy/BodyCopy';
import { SelectVariantStyles, closeButtonStyles } from './SelectVariant.style';
import { withProduct } from '../../../utils/ProductContext';
import {
  getVariant,
  getUniqueColorVariants,
  getSelectedColorSwatchIndex,
  getFirstMatchingVariantId,
  getUniqueSizes,
  getUniqueOperators
} from '../../../utils/variant';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import Prices from '../../Prices/Prices';
import constants from '../../../config/constants';
import ProductColorSwatches from '../../ProductColorSwatches/ProductColorSwatches';
import _ from '../../../utils/LodashImports';
import { withLabels } from '../../../utils/LabelsContext';
import Size from '../../Size/Size';
import Button from '../../ui/Button/Button';
import { track } from '../../../external_dependencies/analytic/index';
import * as productUtils from '../../../utils/product/productUtils';
import Operator from '../../Operator/Operator';
import { ModalHeader } from '../../NearbyStores/CustomModal/CustomModal';
import noop from '../../../utils/noop';
import getImages from '../../../utils/GetImages';
import useInput from '../../../containers/ProdctSpecifications/useInput';

const CONFIGS = {
  desktop: {
    brandName: {
      size: 'title21',
      type: 'primary'
    },
    description: {
      size: 'title19',
      type: 'primary'
    },
    price: 'CART'
  },
  mobile: {
    brandName: {
      size: 'title18',
      type: 'primary'
    },
    description: {
      size: 'title20',
      type: 'primary'
    },
    price: 'CART'
  }
};

const SelectVariant = ({
  cart,
  product,
  appCtx,
  labels,
  config,
  handleOnClose,
  stockButton,
  inDeliveryOptions,
  checkAvailability
}) => {
  const isConnectProduct = productUtils.isConnectProduct(product);
  const isNewPriceApplicable = _.get(
    appCtx,
    'siteConfig.toggles.isNewPriceApplicable',
    false
  );
  const isCFMediasEnabled = _.get(
    appCtx,
    'siteConfig.toggles.isCFMediasEnabled',
    false
  );
  const sellerName = _.get(
    product,
    'variants[0].offerings[0].sellerName',
    ''
  ).toLowerCase();

  const {
    brandName,
    name,
    currentVariant: currentVariantId,
    variants,
    id,
    changeVariant,
    colorMetrics
  } = product;
  const {
    isSizeSelected,
    setSizeSelection,
    selectedSize,
    setSelectedSize,
    setSelectedOperatorId,
    setVariantSelectionVisible
  } = cart;
  const currentVariant = getVariant(variants, currentVariantId);
  const { prices } = currentVariant;
  const { deviceType, regionCode, tenant, isRebrandingEnabled, store } = appCtx;
  const [isAddToCartInProgress, setIsAddToCartInProgress] = useState(false);
  const [imageURL, setImageUrl] = useState('');
  const offeringId = _.get(currentVariant, 'offerings[0]offeringId', '');
  const sellerId = _.get(product, 'variants[0].offerings[0].sellerId', '');
  const isBuyNowApplicable = cart.isBuyNowApplicable(
    _.get(appCtx, 'siteConfig.toggles'),
    sellerId
  );
  const { maxOnCartAmount } = product;
  const { inputVal } = useInput(maxOnCartAmount);
  const viewConfig = CONFIGS[deviceType] || CONFIGS.mobile;

  const colorVariants = getUniqueColorVariants(
    product.variants,
    currentVariant
  );

  const [selectedColorSwatchIndex, setColorIndex] = useState(
    getSelectedColorSwatchIndex(colorVariants, currentVariant)
  );

  const currentColorName = colorVariants[selectedColorSwatchIndex]
    ? colorVariants[selectedColorSwatchIndex].label
    : undefined;

  const sizes = getUniqueSizes(product.variants, currentColorName);

  const operators = getUniqueOperators(product.variants, currentColorName);

  const operatorChangeHandler = (connectOperator) => {
    if (!connectOperator) {
      setSizeSelection(false);
      setSelectedOperatorId('');
      return;
    }
    const variantId = getFirstMatchingVariantId(variants, {
      colorName: currentColorName,
      size: selectedSize,
      connectOperator
    });
    setSizeSelection(true);
    setSelectedOperatorId(variantId);
    changeVariant(tenant, variantId, store, regionCode);
  };

  const colorSwatchHandler = ({ label: colorName }) => {
    const variantId = getFirstMatchingVariantId(variants, {
      colorName,
      size: selectedSize
    });
    changeVariant(tenant, variantId, store, regionCode);
  };

  const sizeChangeHandler = (size) => {
    const variantId = getFirstMatchingVariantId(variants, {
      colorName: currentColorName,
      size
    });
    const newVariant = getVariant(variants, variantId);
    const currentSize = _.get(newVariant, 'attributes.size', '');

    const newSize =
      size.toUpperCase() === currentSize.toUpperCase() ? size : undefined;

    setSelectedSize(newSize);
    setSizeSelection(true);
    if (inDeliveryOptions) {
      setVariantSelectionVisible(false);
    }
    changeVariant(tenant, variantId, store, regionCode);
  };

  const type =
    product.productType === constants.PRODUCT_CONSTANTS.CONNECT
      ? constants.PRODUCT_CONSTANTS.CONNECT
      : constants.PRODUCT_CONSTANTS.HARDLINE;

  const { sendIsColorPurshased } = colorMetrics;
  const handleAddToCart = () => {
    sendIsColorPurshased(currentColorName);
    if (isAddToCartInProgress) {
      return;
    }

    const products = [
      {
        skuId: currentVariantId,
        productId: id,
        quantity: cart.quantityWhileVariantSelectionVisible || 1,
        offeringId,
        isInternational: _.get(
          product,
          'internationalShipping.applicable',
          false
        )
      }
    ];

    setIsAddToCartInProgress(true);
    cart
      .addProduct({
        appCtx,
        replaceCartItems: true,
        products,
        isBuyNowApplicable,
        product
      })
      .then((err) => {
        if (!err) {
          track('addToCart');
          const eventToDigitalData = new CustomEvent(
            'DDPDPATCQuantitySelector',
            {
              detail: {
                maxCartQuantity: maxOnCartAmount,
                value: inputVal
              },
              bubbles: true
            }
          );
          window.dispatchEvent(eventToDigitalData);
        }
        setIsAddToCartInProgress(false);
      });
  };

  const isSizeAvailable = (sizeList) => {
    const selected = sizeList.find((size) => size.value === selectedSize);
    return selected && selected.available;
  };

  const validateSelectedSize = () => {
    if (!selectedSize) {
      return;
    }
    if (!isSizeAvailable(sizes)) {
      setSelectedSize(null);
      setSizeSelection(false);
    }
  };

  useEffect(() => {
    validateSelectedSize();
  }, [sizes]);

  useEffect(() => {
    const mediaUrl = getImages({
      variantId: currentVariantId,
      regionCode,
      attr: {
        width: 92,
        height: 92,
        quality: 70
      },
      isCFMediasEnabled,
      sellerName,
      url: _.get(currentVariant, 'medias[0].url', '')
    });

    setImageUrl(mediaUrl);
    setColorIndex(getSelectedColorSwatchIndex(colorVariants, currentVariant));
  }, [currentVariant, product, currentVariantId]);

  const closeModal = (e) => {
    if (handleOnClose) {
      handleOnClose();
      e.stopPropagation();
    }
    cart.setShowCart(false);
  };

  const {
    ADDING_TO_CART = '',
    ADD_TO_CART_TEXT = '',
    SELECT_VARIANT_CHOOSE_OPTIONS = 'ELIGE TUS OPCIONES',
    ADD_TO_CART_TEXT_MKP = 'Agregar al carro',
    SELECT_VARIANT_CHOOSE_OPTIONS_MKP = 'Elige tus opciones'
  } = labels;

  const cartButton = () => {
    let buttonLabel = '';
    if (isBuyNowApplicable) {
      if (isRebrandingEnabled) {
        buttonLabel = _.get(labels, 'BUY_NOW_BUTTON_TEXT_MKP', 'Comprar Ahora');
      } else {
        buttonLabel = _.get(labels, 'BUY_NOW_BUTTON_TEXT', 'COMPRAR AHORA');
      }
    } else if (isRebrandingEnabled) {
      buttonLabel = isSodimacStandalone(appCtx.store)
        ? _.get(labels, 'SO_COM_ADD_TO_CART_TEXT', 'Agregar al carro')
        : ADD_TO_CART_TEXT_MKP;
    } else {
      buttonLabel = ADD_TO_CART_TEXT;
    }
    const addToCartButton = () =>
      isAddToCartInProgress ? (
        <Button
          disabled
          size="medium"
          type={isRebrandingEnabled ? `mkp-secondary` : `primary`}
          key="select-variant-add-to-cart-loading"
          letterSpace={isRebrandingEnabled ? `small` : ''}
        >
          {ADDING_TO_CART}
        </Button>
      ) : (
        <Button
          id="add-to-cart-button-lightbox"
          onClick={handleAddToCart}
          size="medium"
          type={isRebrandingEnabled ? `mkp-secondary` : `primary`}
          key="select-variant-add-to-cart"
          strech={isRebrandingEnabled ? `auto` : ''}
          letterSpace={isRebrandingEnabled ? `small` : ''}
        >
          {buttonLabel}
        </Button>
      );

    return isSizeSelected ? (
      addToCartButton()
    ) : (
      <Button
        disabled
        size="medium"
        id="select-your-options-btn"
        type={isRebrandingEnabled ? `mkp-secondary` : `primary`}
        key="select-variant-selected-add-to-cart"
        letterSpace={isRebrandingEnabled ? `small` : ''}
      >
        {isRebrandingEnabled
          ? SELECT_VARIANT_CHOOSE_OPTIONS_MKP
          : SELECT_VARIANT_CHOOSE_OPTIONS}
      </Button>
    );
  };

  const availabilityButton = () => {
    const btnText = 'Ver disponibilidad';
    return isSizeSelected ? (
      <Button
        id="testId-availability-btn"
        onClick={() => {
          checkAvailability();
        }}
        size="medium"
        type={isRebrandingEnabled ? `mkp-secondary` : `secondary`}
        key="select-variant-availability"
        strech={isRebrandingEnabled && deviceType === 'desktop' ? `auto` : ''}
        letterSpace={isRebrandingEnabled ? `small` : ''}
      >
        {btnText}
      </Button>
    ) : (
      <Button
        disabled
        size="medium"
        type={isRebrandingEnabled ? `mkp-secondary` : `secondary`}
        key="select-variant-selected-availability"
        letterSpace={isRebrandingEnabled ? `small` : ''}
      >
        {btnText}
      </Button>
    );
  };

  const CloseButton = ({ styleType }) => {
    return (
      <React.Fragment>
        <button
          className={`cerrar-button ${styleType}`}
          type="button"
          onClick={closeModal}
        >
          <span className="cerrar-button-text">
            {inDeliveryOptions ? 'Cancelar' : labels.CLEAR}
          </span>
        </button>
        <style jsx>{closeButtonStyles}</style>
      </React.Fragment>
    );
  };
  CloseButton.defaultProps = {
    styleType: ''
  };
  CloseButton.propTypes = {
    styleType: PropTypes.object
  };

  return (
    <div
      className={`popup ${stockButton ? 'stockEnTienda' : ''} ${
        inDeliveryOptions ? 'deliveryOptions' : ''
      }`}
    >
      {stockButton && deviceType !== 'desktop' ? (
        <div className="store-availability-header">
          <ModalHeader title={config.mobileTitle} icon={config.mobileIcon} />
          <button type="button" className="close-button" onClick={closeModal}>
            <i className="csicon-danger">
              <span className="sr-only">{labels.CLEAR}</span>
            </i>
          </button>
        </div>
      ) : (
        <div className={`header ${inDeliveryOptions ? 'deliveryOptions' : ''}`}>
          {inDeliveryOptions && deviceType !== 'desktop' ? (
            <i className={`${config.iconClass}`} />
          ) : (
            <i className="warning-icon">
              <span className="sr-only">
                {config.title || labels.SELECT_VARIANT_WARNING_MESSAGE}
              </span>
            </i>
          )}
          <span
            className={`label ${inDeliveryOptions ? 'deliveryOptions' : ''}`}
          >
            <BodyCopy
              size={`${
                inDeliveryOptions && deviceType !== 'desktop'
                  ? 'copy16'
                  : 'copy13'
              }`}
            >
              {config.title || labels.SELECT_VARIANT_WARNING_MESSAGE}
            </BodyCopy>
          </span>
          <button
            type="button"
            className={`close-button ${inDeliveryOptions ? 'hide' : ''}`}
            onClick={closeModal}
          >
            <i className="csicon-danger">
              <span className="sr-only">{labels.CLEAR}</span>
            </i>
          </button>
        </div>
      )}
      <div className={`body ${inDeliveryOptions ? 'deliveryOptions' : ''}`}>
        <div
          className={`product-details ${
            inDeliveryOptions ? 'deliveryOptions' : ''
          }`}
        >
          <img className="product-image" src={imageURL} alt={name} />
          <div className="info-wrapper">
            <div className="item-info">
              <BodyCopy
                type={viewConfig.brandName.type}
                size={viewConfig.brandName.size}
                isHighlighted
              >
                {brandName.toUpperCase()}
              </BodyCopy>
              <BodyCopy
                type={viewConfig.description.type}
                size={viewConfig.description.size}
              >
                {name}
              </BodyCopy>
            </div>
            <div
              className={classNames('price mt-8px mt-0', {
                mkp: isRebrandingEnabled,
                'mt-8p': isNewPriceApplicable
              })}
            >
              <Prices
                productId={id}
                priceList={prices}
                variantId={currentVariant.comboData ? currentVariant.id : null}
                variant={viewConfig.price}
              />
            </div>
          </div>
        </div>
        {stockButton && deviceType !== 'desktop' ? (
          <p className="stock-en-tienda-option-text">
            {config.title || labels.SELECT_VARIANT_WARNING_MESSAGE}
          </p>
        ) : (
          <React.Fragment />
        )}
        <div
          className={
            isRebrandingEnabled ? 'variant-details mkp' : 'variant-details'
          }
        >
          {inDeliveryOptions && deviceType !== 'desktop' && (
            <div className="cual-buscas">
              <BodyCopy size="copy10">¿Cuál buscas?</BodyCopy>
            </div>
          )}
          {colorVariants[selectedColorSwatchIndex] && (
            <div
              className={`color-variants ${
                inDeliveryOptions ? 'deliveryOptions' : ''
              }`}
            >
              <Fragment>
                <BodyCopy
                  isHighlighted
                  size={deviceType === 'mobile' ? 'copy1' : 'copy3'}
                >{`${labels.COLOR}: `}</BodyCopy>
                <BodyCopy size={deviceType === 'mobile' ? 'copy1' : 'copy3'}>
                  {colorVariants[selectedColorSwatchIndex].label}
                </BodyCopy>
              </Fragment>

              <ProductColorSwatches
                colorVariants={colorVariants}
                productId={product.id}
                selectedColorSwatchIndex={selectedColorSwatchIndex}
                colorSwatchHandler={colorSwatchHandler}
                type={type}
                size="medium"
                appCtx={appCtx}
                colorMetrics={colorMetrics}
              />
            </div>
          )}
          {operators.length || sizes.length ? (
            <div
              className={`size-variants ${
                inDeliveryOptions ? 'deliveryOptions' : ''
              }`}
            >
              {isConnectProduct ? (
                <Operator
                  isDesktop
                  operators={operators}
                  operatorChangeHandler={operatorChangeHandler}
                  hideNotifications
                  hasNoMargin
                  isRebrandingEnabled={isRebrandingEnabled}
                />
              ) : (
                <Size
                  title={labels.SELECTION_SIZE}
                  sizes={sizes}
                  selectedSize={selectedSize}
                  onSizeChange={sizeChangeHandler}
                  isSizeChartHidden
                  isRebrandingEnabled={isRebrandingEnabled}
                />
              )}
            </div>
          ) : (
            <React.Fragment />
          )}

          {inDeliveryOptions ? (
            <div className="see-availability-container">
              <CloseButton />
              <div className="availability-btn-container">
                {availabilityButton()}
              </div>
            </div>
          ) : (
            <div
              className={`addToCart-container ${
                isRebrandingEnabled ? 'mkp' : ''
              }`}
            >
              {stockButton ? stockButton() : cartButton()}
              <CloseButton styleType="float" />
            </div>
          )}
        </div>
      </div>
      <style jsx>{SelectVariantStyles}</style>
    </div>
  );
};

SelectVariant.defaultProps = {
  config: {},
  handleOnClose: undefined,
  stockButton: undefined,
  inDeliveryOptions: false,
  checkAvailability: noop
};

SelectVariant.propTypes = {
  cart: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  config: PropTypes.object,
  handleOnClose: PropTypes.func,
  stockButton: PropTypes.node,
  inDeliveryOptions: PropTypes.bool,
  checkAvailability: PropTypes.func
};

export default withProduct(withApplicationContext(withLabels(SelectVariant)));

export { SelectVariant };
