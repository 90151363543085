import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import { isSodimacStandalone } from '@digital-retail/store-manager';
import Prices from '../../Prices/Prices';
import _ from '../../../utils/LodashImports';
import { getTenant } from '../../../utils/tenantHelper';
import Image from '../../ui/Image/Image';
import { ComparativeTablePodStyles } from './ComparativeTablePod.style';
import { withLabels } from '../../../utils/LabelsContext';
import RatingsVisual from '../../ui/RatingsVisual/RatingsVisual';
import Reviews from '../../ui/Reviews/Reviews';
import { isWindow } from '../../../utils/deviceChecker';
import useIntersection from '../../../utils/useIntersection';
import useLazyLoadImage from '../../../utils/useLazyLoadImage';
import noop from '../../../utils/noop';
import Badges from '../../Badges/Badges';

const ComparativeTablePod = ({
  product,
  appCtx,
  labels,
  details,
  aggregateViewedProducts,
  addToCart,
  podClicked,
  addedToCart,
  totalProducts,
  currentVariant,
  slot,
  theme
}) => {
  const themedStyles = ComparativeTablePodStyles(theme);

  const { deviceType, regionCode } = appCtx;
  const productBrand = _.get(product, 'brand', '');
  const [loadImage, setLoadImage] = useState(false);
  const meatStickers = _.get(product, 'meatStickers', []);
  const isMoreThanOneRating = product.totalReviews && product.totalReviews > 0;
  const href = product.url ? product.url : '#';

  const productRef = React.useRef(null);
  const isCarouselInViewport = useIntersection(productRef, '0px', '1', false);

  useLazyLoadImage({ productRef, setLoadImage });

  if (isWindow() && aggregateViewedProducts) {
    if (isCarouselInViewport) {
      aggregateViewedProducts({
        sku: product.id,
        selectedTab: details.selectedTab
      });
    }
  }

  const redirectToProduct = () => {
    const eventData = {
      carouselName: details.carouselName,
      currentProductID: details.parentVariantID,
      targetProductID: product.offeringId,
      numberProducts: details.numberProducts,
      productPosition: details.productPosition,
      carouselPosition: details.carouselPosition,
      country: getTenant(regionCode),
      store: appCtx.store
    };
    podClicked(product.id);
    const eventToDigitalData = new CustomEvent('DDPDPCarouselProductClick', {
      bubbles: true,
      detail: eventData
    });
    window.dispatchEvent(eventToDigitalData);
  };
  const imageSrc = product.mediaUrls && product.mediaUrls[0];

  const handleAddToCartClick = () => {
    addedToCart(product.id);
    const detail = {
      productToAdd: product.productId,
      tenant: getTenant(appCtx.regionCode),
      currentVariant,
      totalProductInReco: totalProducts,
      position: details.productPosition,
      store: appCtx.store,
      slot,
      heading: details.carouselName
    };
    const eventToDigitalData = new CustomEvent('DDPDPComparativeATC', {
      bubbles: true,
      detail
    });
    window.dispatchEvent(eventToDigitalData);
    addToCart({
      skuId: product.id,
      quantity: 1,
      offeringId: product.offeringId
    });
  };

  return (
    <div className="comparative-table-pod-wrapper">
      {meatStickers && (
        <Badges badgeItems={meatStickers} layout="PDP POD" isSticker />
      )}
      <a
        href={href}
        id="testId-comparative-table-redirect"
        onClick={redirectToProduct}
        className="pod-anchor"
        aria-hidden="true"
      >
        <div className="comparative-table-pod" ref={productRef}>
          <div className="comparative-table-pod-layout">
            <div className="image-container">
              <div className="image-wrapper">
                {loadImage && (
                  <Image
                    id={product.id}
                    width={138}
                    height={115}
                    alt={product.displayName}
                    useSrcSet={false}
                    inlineDimensions
                    imageUrl={imageSrc}
                    type="comparative-pod"
                    lazyLoad
                  />
                )}
              </div>
            </div>
            <div className="pod-details">
              <div className="pod-product-brand">{productBrand}</div>
              <div className="pod-product-description">
                {product.displayName}
              </div>
              {isMoreThanOneRating && (
                <div className="pod-rating">
                  <RatingsVisual
                    value={Number(product.rating)}
                    productId={product.productId}
                    size="normal"
                  />
                  <Reviews value={Number(product.totalReviews)} />
                </div>
              )}
              <div className="pod-product-price">
                <Prices
                  priceList={product.prices}
                  productId={product.productId}
                  discountBadge={product.discountBadge}
                  page="PDP"
                  layout="4_GRID"
                  variant="DESKTOP_4_GRID"
                  showMyList={false}
                />
              </div>
            </div>
          </div>
        </div>
      </a>
      <div className="pod-button">
        <btn
          id="testId-comparative-atc"
          className={`button ${
            isSodimacStandalone(appCtx.store) ? 'so_com' : ''
          }`}
          onClick={handleAddToCartClick}
        >
          <span className="add-to-cart-text">
            {deviceType === 'desktop' ? (
              labels.ADD_TO_CART_TEXT_MKP
            ) : (
              <div className="mobile-text">
                <span>{labels.ADD_TO_CART_TEXT_ALONE}</span>
                <div className="cart-icon" />
              </div>
            )}
          </span>
        </btn>
      </div>
      <style jsx>{themedStyles}</style>
    </div>
  );
};

ComparativeTablePod.propTypes = {
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  labels: PropTypes.object,
  details: PropTypes.object.isRequired,
  aggregateViewedProducts: PropTypes.func.isRequired,
  podClicked: PropTypes.func.isRequired,
  addedToCart: PropTypes.func.isRequired,
  addToCart: PropTypes.func,
  totalProducts: PropTypes.number.isRequired,
  currentVariant: PropTypes.string.isRequired,
  slot: PropTypes.string.isRequired,
  theme: PropTypes.object
};
ComparativeTablePod.defaultProps = {
  labels: { ADD_TO_CART: 'Ver producto' },
  addToCart: noop,
  theme: {}
};
export { ComparativeTablePod };
export default withTheme(withLabels(ComparativeTablePod));
