import { getCommerceHeaders as getStoreCommerceHeaders } from '@digital-retail/store-manager';
import { isMkp, getSidCookie } from './urlHelper';
import storeMap from '../config/storeMap';

const tenants = {
  cl: 'FACL',
  co: 'FACO',
  pe: 'FAPE',
  ar: 'FAAR'
};

export const tenantNameSpace = {
  cl: 'falabella-cl',
  co: 'falabella-co',
  pe: 'falabella-pe',
  ar: 'falabella-ar'
};

const getTenant = (regionCode) => {
  return tenants[regionCode];
};

const getTenantNameSpace = (regionCode) => {
  return tenantNameSpace[regionCode];
};

const getCommerceHeaders = (regionCode, store, includeAuth = false) => {
  const sid = getSidCookie();
  const isMarketPlace = isMkp();
  const storeCommerceHeaders = getStoreCommerceHeaders({
    regionCode,
    store,
    isMarketPlace
  });
  const headers = {
    'x-channel-id': 'WEB',
    'x-commerce': 'FCM',
    'x-origin-name': 'PDP',
    'x-origin-view': 'Product',
    ...storeCommerceHeaders
  };

  if (sid && includeAuth) {
    headers.Authorization = sid;
  }
  return headers;
};

const getStoreValue = (seller) => {
  return storeMap[seller] ? seller.toLowerCase() : 'falabella';
};

export { getTenant, getTenantNameSpace, getCommerceHeaders, getStoreValue };
